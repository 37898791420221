import { Box } from "@mui/material";
import Layout from "../../Layout";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Outlet } from "react-router-dom";
import { AccountProvider } from "../../contexts/AccountContext";
import AccountMenu from "./AccountMenu";

const Account = () => {
    return (
        <Layout>
            <AccountProvider>
                <Box
                    height={{ lg: "100%" }}
                    boxSizing={"border-box"}
                    pt={"8px"}
                    bgcolor={"var(--white)"}
                >
                    <Grid2 container p={"40px"} spacing={"40px"} height={"100%"}>
                        <Grid2
                            xs={12}
                            lg={4}
                            maxWidth={{ lg: "338px" }}
                            height={{
                                lg: "100%",
                            }}
                        >
                            <AccountMenu />
                        </Grid2>
                        <Grid2
                            xs
                            height={{
                                lg: "100%",
                            }}
                        >
                            <Outlet />
                        </Grid2>
                    </Grid2>
                </Box>
            </AccountProvider>
        </Layout>
    )
}

export default Account;