import { SvgIcon, SvgIconProps } from "@mui/material";

const Buildings = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="vuesax/outline/buildings">
          <g id="buildings">
            <path
              id="Vector"
              d="M13 23.25H5C2.58 23.25 1.25 21.92 1.25 19.5V11.5C1.25 9.08 2.58 7.75 5 7.75H10C10.41 7.75 10.75 8.09 10.75 8.5V19.5C10.75 21.08 11.42 21.75 13 21.75C13.41 21.75 13.75 22.09 13.75 22.5C13.75 22.91 13.41 23.25 13 23.25ZM5 9.25C3.42 9.25 2.75 9.92 2.75 11.5V19.5C2.75 21.08 3.42 21.75 5 21.75H9.79999C9.43999 21.16 9.25 20.41 9.25 19.5V9.25H5Z"
              fill="currentColor"
            />
            <path
              id="Vector_2"
              d="M10 9.25H5C4.59 9.25 4.25 8.91 4.25 8.5V6.5C4.25 4.98 5.48 3.75 7 3.75H10.11C10.34 3.75 10.56 3.85998 10.7 4.03998C10.84 4.22998 10.89 4.47 10.83 4.69C10.77 4.91 10.75 5.16 10.75 5.5V8.5C10.75 8.91 10.41 9.25 10 9.25ZM5.75 7.75H9.25V5.5C9.25 5.41 9.25 5.33 9.25 5.25H7C6.31 5.25 5.75 5.81 5.75 6.5V7.75Z"
              fill="currentColor"
            />
            <path
              id="Vector_3"
              d="M14 14.25C13.59 14.25 13.25 13.91 13.25 13.5V8.5C13.25 8.09 13.59 7.75 14 7.75C14.41 7.75 14.75 8.09 14.75 8.5V13.5C14.75 13.91 14.41 14.25 14 14.25Z"
              fill="currentColor"
            />
            <path
              id="Vector_4"
              d="M18 14.25C17.59 14.25 17.25 13.91 17.25 13.5V8.5C17.25 8.09 17.59 7.75 18 7.75C18.41 7.75 18.75 8.09 18.75 8.5V13.5C18.75 13.91 18.41 14.25 18 14.25Z"
              fill="currentColor"
            />
            <path
              id="Vector_5"
              d="M18 23.25H14C13.59 23.25 13.25 22.91 13.25 22.5V18.5C13.25 17.54 14.04 16.75 15 16.75H17C17.96 16.75 18.75 17.54 18.75 18.5V22.5C18.75 22.91 18.41 23.25 18 23.25ZM14.75 21.75H17.25V18.5C17.25 18.36 17.14 18.25 17 18.25H15C14.86 18.25 14.75 18.36 14.75 18.5V21.75Z"
              fill="currentColor"
            />
            <path
              id="Vector_6"
              d="M6 18.25C5.59 18.25 5.25 17.91 5.25 17.5V13.5C5.25 13.09 5.59 12.75 6 12.75C6.41 12.75 6.75 13.09 6.75 13.5V17.5C6.75 17.91 6.41 18.25 6 18.25Z"
              fill="currentColor"
            />
            <path
              id="Vector_7"
              d="M19 23.25H13C10.58 23.25 9.25 21.92 9.25 19.5V5.5C9.25 3.08 10.58 1.75 13 1.75H19C21.42 1.75 22.75 3.08 22.75 5.5V19.5C22.75 21.92 21.42 23.25 19 23.25ZM13 3.25C11.42 3.25 10.75 3.92 10.75 5.5V19.5C10.75 21.08 11.42 21.75 13 21.75H19C20.58 21.75 21.25 21.08 21.25 19.5V5.5C21.25 3.92 20.58 3.25 19 3.25H13Z"
              fill="currentColor"
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
};

export default Buildings;