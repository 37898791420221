import { Box } from "@mui/material";
import StyledBarChart from "../chart/BarChart";
import ChartCheckbox from "../chart/ChartCheckbox";
import { useContext, useState } from "react";
import { DashbroadContext } from "../../contexts/DashbroadContext";
import { BarSeriesType } from "@mui/x-charts";
import { MakeOptional } from "@mui/x-charts/models/helpers";
import { useTranslation } from "react-i18next";
import Emission, { EmissionData } from "../../models/Emission";
import Group from "../../models/Group";

export const highToLow = 0;
export const LowToHigh = 1;
export const byOrg = 0;
export const bySite = 1;

interface Props {
  groups: Group[];
  getGroup: (emission: EmissionData) => string | undefined;
  by: number;
  sort: number;
}

const EmissionByBusinessGroup = (props: Props) => {
  const { filteredEmissions } = useContext(DashbroadContext);
  const [checkRecord, setCheckRecord] = useState<Record<string, boolean>>(
    () => {
      const record: Record<string, boolean> = {};
      props.groups.forEach((group) => {
        record[group.id] = true;
      });
      return record;
    }
  );
  const sort = props.sort;
  const by = props.by;

  const { t } = useTranslation(["common", "overview"], { nsMode: "fallback" });

  const filterEmissions = filteredEmissions.filter((item) => {
    const group = props.getGroup(item);
    if (group === undefined) return false;
    return checkRecord[group] ?? false;
  });

  const BusinessSumRecord: Record<string, number> = {};
  filterEmissions.forEach((item) => {
    let key;
    if (by === byOrg) {
      key = item.organizationName ?? "";
    } else {
      key = item.suborganizationName ?? "";
    }
    const old = BusinessSumRecord[key] ?? 0;
    BusinessSumRecord[key] = old + item.value;
  });

  let sortBusinessSum: [string, number][];
  if (sort === highToLow) {
    sortBusinessSum = Object.entries(BusinessSumRecord).sort(
      ([keyA, a], [keyB, b]) => b - a
    );
  } else {
    sortBusinessSum = Object.entries(BusinessSumRecord).sort(
      ([keyA, a], [keyB, b]) => a - b
    );
  }

  const byBusinessRecord: Record<string, Record<string, number>> = {};
  const businessKeys = sortBusinessSum.map(([key, value]) => key);
  filterEmissions.forEach((item) => {
    const group = props.getGroup(item);
    if (group === undefined) return false;
    const old = byBusinessRecord[group] ?? {};

    let key;
    if (by === byOrg) {
      key = item.organizationName ?? "";
    } else {
      key = item.suborganizationName ?? "";
    }
    const oldValue = old[key] ?? 0;
    old[key] = oldValue + item.value;

    byBusinessRecord[group] = old;
  });

  const series: MakeOptional<BarSeriesType, "type">[] = [];
  Object.entries(byBusinessRecord).forEach(([groupId, groupSeries]) => {
    const group = props.groups.find((item) => item.id === groupId);
    if (group !== undefined) {
      const color = group.colors;
      let data: number[] = [];
      businessKeys.forEach((key) => {
        data.push(groupSeries[key] ?? 0);
      });
      series.push({
        data: data,
        label: group?.title,
        stack: "",
        color: color[1],
      });
    }
  });

  const checkboxs = props.groups.map((group) => {
    return (
      <ChartCheckbox
        key={group.id}
        text={t(group.title)}
        color={group.colors[1]}
        checked={checkRecord[group.id] ?? false}
        onChange={(_, checked) => {
          checkRecord[group.id] = checked;
          setCheckRecord({ ...checkRecord });
        }}
      />
    );
  });

  return (
    <Box>
      <Box
        display={"flex"}
        justifyContent={"flex-end"}
        gap={"24px"}
        flexWrap={"wrap"}
      >
        {checkboxs}
      </Box>
      <Box
        flexShrink={0}
        width={"100%"}
        // height={"100%"}
        sx={{ overflow: "auto" }}
      >
        <StyledBarChart
          layout="horizontal"
          series={series}
          seriesLabel="tCO2e"
          labels={Array.from(businessKeys)}
          left={160}
        />
      </Box>
    </Box>
  );
};

export default EmissionByBusinessGroup;
