import { useContext } from "react";
import { OrganizationContext } from "../../contexts/OrganizationContext";
import OrganizationProfile from "./OrganizationProfile";
import SubOrganizationProfile from "./SubOrganizationProfile";

const BusinessProfile = () => {
    const context = useContext(OrganizationContext);

    if (!context) {
        throw Error();
    }

    if (context.selectedSubOrganization === undefined) {
        return <OrganizationProfile />;
    } else {
        return <SubOrganizationProfile />;
    }
};

export default BusinessProfile;
