import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import CustomModal from "../../../components/modal/CustomModal";
import { Trans, useTranslation } from "react-i18next";
import { useEffect } from "react";
import { FormProvider } from "react-hook-form";

import {
  AddEmissionContext,
  EmissionMode,
  FormValuesStepOne,
  FormValuesStepTwo,
} from "../../../contexts/AddEmissionContext";
import TemplateActivitiesService from "../../../service/templateActivitiesService";
import alertService from "../../../components/alert/alertService";
import { OrganizationBreadcrumbs } from "../../emission/OrganizationBreadcrumbs";
import AddEmissionStepOne from "../../emission/popup-add-emission/step1/AddEmissionStepOne";
import AddEmissionStepTwo from "../../emission/popup-add-emission/step2/AddEmissionStepTwo";
import { ActivityTemplatesContext } from "../../../contexts/ActivityTemplatesContext";
import { useAuth } from "../../../contexts/UserContext";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export const AddActivityTemplatesModal = () => {
  const { t } = useTranslation(["common", "input"], {
    nsMode: "fallback",
  });

  const { transformersToDataGrid } = ActivityTemplatesContext();

  const {
    step,
    setStep,
    open,
    handleOpen,
    handleClose,
    stepOneForm,
    stepTwoForm,
    setEmissionMode,
    subOrganizationList,
    prepareFormDataToCreateOrDraftTemplates,
    getSubOrganizationList,
    getEmissionGroupList,
    getAssetList,
  } = AddEmissionContext();

  const closeModal = () => {
    stepOneForm.reset();
    stepTwoForm.reset();
    handleClose();
  };

  const stepOneOnSubmit = (data: FormValuesStepOne) => {
    setStep(step + 1);
  };

  const stepTwoOnSubmit = async (data: FormValuesStepTwo, event: any) => {
    if (event.nativeEvent.submitter.name === "addTemplates") {
      const result = await TemplateActivitiesService.createTemplateActivities(
        prepareFormDataToCreateOrDraftTemplates(true)
      );

      if (result !== null) {
        transformersToDataGrid(result.templateActivities);
        closeModal();
        alertService.mixin(t("Successfully"));
      }

      return;
    } else if (event.nativeEvent.submitter.name === "draftTemplates") {
      const result = await TemplateActivitiesService.createTemplateActivities(
        prepareFormDataToCreateOrDraftTemplates(false)
      );

      if (result !== null) {
        transformersToDataGrid(result.templateActivities);
        closeModal();
        alertService.mixin(t("Successfully"));
      }

      return;
    }
  };

  const monthly = stepTwoForm.watch("monthly") ?? false;

  const monthlyRegenerate = async () => {
    const confirm = await alertService.confirm(
      <Trans t={t} i18nKey="generate-activity-templates" />,
      t("generate-activity-templates-confirm")
    );

    if (confirm) {
      const result =
        await TemplateActivitiesService.monthlyRegenerateTemplateActivities();

      if (result) {
        if (result.generatedActivities.length === 0) {
          // สร้างไม่ได้ มันซ้ำ
          alertService.info(
            t("generate-monthly-activity-templates_already_exists")
          );
        } else {
          alertService.confirm(
            t("Successfully"),
            <Box>
              <Typography variant="text1">
                {t("generated-activities")}
              </Typography>
              <List>
                {result.generatedActivities.map((e) => (
                  <>
                    <ListItem>
                      <ListItemIcon>
                        <CheckCircleOutlineIcon color={"primary"} />
                      </ListItemIcon>
                      <ListItemText
                        primary={`AT-${e.templateId}`}
                        secondary={`${e.generated} เดือน`}
                      />
                    </ListItem>
                    <Divider component="li" variant="inset" />
                  </>
                ))}
              </List>
            </Box>
          );
        }
      }
    }
  };

  const { isAdmin } = useAuth();

  useEffect(() => {
    setEmissionMode(EmissionMode.AddActivityTemplates);
    getSubOrganizationList();
    getEmissionGroupList();
    getAssetList();
  }, []);

  return (
    <>
      <Box display={"flex"} flexDirection={"row"} gap={2.5}>
        <Button
          variant="contained"
          color="primary"
          onClick={monthlyRegenerate}
          startIcon={<img src="/img/add-square.svg" alt="add-square" />}
        >
          {t("generate-activity-templates")}
        </Button>

        <Button
          variant="contained"
          color="secondary"
          startIcon={<img src="/img/add-circle.svg" alt="add-circle" />}
          onClick={handleOpen}
          disabled={!isAdmin()}
        >
          {t("add-activity-templates")}
        </Button>
      </Box>

      <CustomModal
        header={
          <header>
            <Typography variant="h6">
              {step === 2 ? (
                <IconButton
                  sx={{
                    marginLeft: "-8px",
                  }}
                  onClick={() => {
                    stepTwoForm.reset();
                    setStep(step - 1);
                  }}
                >
                  <img src="/img/arrow-left.svg" alt="" />
                </IconButton>
              ) : (
                <span></span>
              )}
              {t("add-activity-templates")}({step}/2)
            </Typography>
            {step === 2 ? (
              <OrganizationBreadcrumbs
                organization={stepOneForm.getValues("organization") ?? ""}
                location={stepOneForm.getValues("location") ?? ""}
                subOrganizations={
                  subOrganizationList.find(
                    (i) => i.id === stepOneForm.getValues("subOrganizations")
                  )?.name ?? ""
                }
              />
            ) : null}
          </header>
        }
        body={
          <Box>
            {step === 1 && (
              <FormProvider {...stepOneForm}>
                <form onSubmit={stepOneForm.handleSubmit(stepOneOnSubmit)}>
                  <Box>
                    <AddEmissionStepOne />
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "center", mt: 2 }}
                  >
                    <Button
                      fullWidth
                      color="secondary"
                      variant="contained"
                      type="submit"
                    >
                      {t("Next")}
                    </Button>
                  </Box>
                </form>
              </FormProvider>
            )}

            {step === 2 && (
              <FormProvider {...stepTwoForm}>
                <form onSubmit={stepTwoForm.handleSubmit(stepTwoOnSubmit)}>
                  <Box>
                    <AddEmissionStepTwo />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      paddingTop: "44px",
                    }}
                    rowGap={2.5}
                  >
                    <Button
                      fullWidth
                      color="secondary"
                      variant="contained"
                      type="submit"
                      name="addTemplates"
                      disabled={monthly ? false : true}
                    >
                      {t("add-and-generate-activity-templates")}
                    </Button>
                    <Button
                      color="secondary"
                      variant="outlined"
                      type="submit"
                      name="draftTemplates"
                    >
                      {t("add-activity-templates")}
                    </Button>
                  </Box>
                </form>
              </FormProvider>
            )}
          </Box>
        }
        open={open}
        onClose={closeModal}
      />
    </>
  );
};
