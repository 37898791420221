import {
  Card,
  CardContent,
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import InfoPopper from "../popper/InfoPopper";
import { useState } from "react";
import Emission, { EmissionData } from "../../models/Emission";
import Group from "../../models/Group";
import dayjs from "dayjs";
import StyledBarChart from "../chart/BarChart";
import ChartCheckbox from "../chart/ChartCheckbox";
import { BarSeriesType } from "@mui/x-charts";
import { MakeOptional } from "@mui/x-date-pickers/internals";
import { TFunction } from "i18next";
import NumberFormatterUtils from "../../utils/numberFormatterUtils";

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const byChart = 0;
export const byTable = 1;
const showYear = 3;

interface TableData {
  month?: number;
  year: number;
  group: number[];
  total: number;
  previousTotal: number;
}

function getNumbersFromNtoM(n: number, m: number): number[] {
  const numbers: number[] = [];
  for (let i = n; i <= m; i++) {
    numbers.push(i);
  }
  return numbers;
}

function getChartSeries(
  groups: Group[],
  getGroup: (emission: EmissionData) => string | undefined,
  emissions: EmissionData[],
  startDate: dayjs.Dayjs,
  endDate: dayjs.Dayjs
) {
  const startFilterDate = startDate.add(-(showYear - 1), "year");
  const endFilterDate = endDate;

  const filteredEmissions = emissions
    .filter((item) => {
      return (
        item.date &&
        startFilterDate.valueOf() <= item.date &&
        item.date <= endFilterDate.valueOf()
      );
    })
    .sort((item1, item2) => (item1.date ?? 0) - (item2.date ?? 0));

  const record: Record<
    string,
    Record<number, Record<number, EmissionData[]>>
  > = {};
  filteredEmissions.forEach((item) => {
    if (item.date === undefined) return;
    const group = getGroup(item);
    if (group === undefined) return;
    const date = new Date(item.date);
    const year = date.getFullYear();
    const month = date.getMonth();
    const oldGroup = record[group];
    if (oldGroup === undefined) {
      record[group] = {};
    }
    const oldYear = record[group][year];
    if (oldYear === undefined) {
      record[group][year] = {};
    }
    const old = record[group][year][month] ?? [];
    record[group][year][month] = [...old, item];
  });

  const series: MakeOptional<BarSeriesType, "type">[] = [];
  groups.forEach((group) => {
    const monthData: Record<number, number[]> = [];
    for (
      let date = startDate;
      endDate.diff(date) > 0;
      date = date.add(1, "month")
    ) {
      const year = date.year();
      const month = date.month();
      for (let i = 0; i < showYear; i++) {
        const dataYear = year - i;
        if (monthData[dataYear] === undefined) {
          monthData[dataYear] = [];
        }
        if (
          record[group.id] === undefined ||
          record[group.id][dataYear] === undefined ||
          record[group.id][dataYear][month] === undefined
        ) {
          monthData[dataYear].push(0);
        } else {
          const total = record[group.id][dataYear][month]
            .map((item) => item.value)
            .reduce(
              (previousValue, currentValue) => previousValue + currentValue,
              0
            );
          monthData[dataYear].push(total);
        }
      }
    }

    for (let i = showYear - 1; i >= 0; i--) {
      const year = startDate.year() - i;
      var color;
      if (i === 0) {
        color = 1;
      } else {
        color = 0;
      }
      series.push({
        data: monthData[year],
        label: group.title,
        stack: year.toString(),
        color: group.colors[color],
      });
    }
  });
  return series;
}

function getChartSeriesYear(
  groups: Group[],
  getGroup: (emission: EmissionData) => string | undefined,
  emissions: EmissionData[],
  startDate: dayjs.Dayjs,
  endDate: dayjs.Dayjs
) {
  const startFilterDate = startDate;
  const endFilterDate = endDate;

  const filteredEmissions = emissions
    .filter((item) => {
      return (
        item.date &&
        startFilterDate.valueOf() <= item.date &&
        item.date <= endFilterDate.valueOf()
      );
    })
    .sort((item1, item2) => (item1.date ?? 0) - (item2.date ?? 0));

  // group-year
  const record: Record<string, Record<number, EmissionData[]>> = {};
  filteredEmissions.forEach((item) => {
    if (item.date === undefined) return;
    const group = getGroup(item);
    if (group === undefined) return;
    const date = new Date(item.date);
    const year = date.getFullYear();
    const oldGroup = record[group];
    if (oldGroup === undefined) {
      record[group] = {};
    }
    const oldYear = record[group][year] ?? [];
    record[group][year] = [...oldYear, item];
  });

  const series: MakeOptional<BarSeriesType, "type">[] = [];
  groups.forEach((group) => {
    const yearData: number[] = [];
    for (
      let date = startDate;
      endDate.diff(date) > 0;
      date = date.add(1, "year")
    ) {
      const year = date.year();
      if (
        record[group.id] === undefined ||
        record[group.id][year] === undefined
      ) {
        yearData.push(0);
      } else {
        const total = record[group.id][year]
          .map((item) => item.value)
          .reduce(
            (previousValue, currentValue) => previousValue + currentValue,
            0
          );
        yearData.push(total);
      }
    }

    series.push({
      data: yearData,
      label: group.title,
      stack: "1",
      color: group.colors[1],
    });
  });
  return series;
}

function getTableSeries(
  groups: Group[],
  getGroup: (emission: EmissionData) => string | undefined,
  emissions: EmissionData[],
  startDate: dayjs.Dayjs,
  endDate: dayjs.Dayjs
) {
  const startFilterDate = startDate.add(-1, "year");
  const endFilterDate = endDate;

  const filteredEmissions = emissions
    .filter((item) => {
      return (
        item.date &&
        startFilterDate.valueOf() <= item.date &&
        item.date <= endFilterDate.valueOf()
      );
    })
    .sort((item1, item2) => (item1.date ?? 0) - (item2.date ?? 0));

  const record: Record<
    number,
    Record<number, Record<string, EmissionData[]>>
  > = {};
  filteredEmissions.forEach((item) => {
    if (item.date === undefined) return;
    const group = getGroup(item);
    if (group === undefined) return;
    const date = new Date(item.date);
    const year = date.getFullYear();
    const month = date.getMonth();
    const oldYear = record[year];
    if (oldYear === undefined) {
      record[year] = {};
    }
    const oldMonth = record[year][month];
    if (oldMonth === undefined) {
      record[year][month] = {};
    }
    const oldGroup = record[year][month][group] ?? [];
    record[year][month][group] = [...oldGroup, item];
  });

  const tableData: TableData[] = [];
  for (
    let date = startDate;
    endDate.diff(date) > 0;
    date = date.add(1, "month")
  ) {
    const year = date.year();
    const month = date.month();
    const group = groups.map((group) => {
      if (
        record[year] === undefined ||
        record[year][month] === undefined ||
        record[year][month][group.id] === undefined
      ) {
        return 0;
      }
      const total = record[year][month][group.id]
        .map((item) => item.value)
        .reduce(
          (previousValue, currentValue) => previousValue + currentValue,
          0
        );
      return total;
    });
    const total = group.reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      0
    );

    const previousYear = year - 1;
    const previousGroup = groups.map((group) => {
      if (
        record[previousYear] === undefined ||
        record[previousYear][month] === undefined ||
        record[previousYear][month][group.id] === undefined
      ) {
        return 0;
      }
      const total = record[previousYear][month][group.id]
        .map((item) => item.value)
        .reduce(
          (previousValue, currentValue) => previousValue + currentValue,
          0
        );
      return total;
    });
    const previousTotal = previousGroup.reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      0
    );

    tableData.push({
      month: month,
      year: year,
      group: group,
      total: total,
      previousTotal: previousTotal,
    });
  }
  return tableData;
}

function getTableSeriesYear(
  groups: Group[],
  getGroup: (emission: EmissionData) => string | undefined,
  emissions: EmissionData[],
  startDate: dayjs.Dayjs,
  endDate: dayjs.Dayjs
) {
  const startFilterDate = startDate;
  const endFilterDate = endDate;

  const filteredEmissions = emissions
    .filter((item) => {
      return (
        item.date &&
        startFilterDate.valueOf() <= item.date &&
        item.date <= endFilterDate.valueOf()
      );
    })
    .sort((item1, item2) => (item1.date ?? 0) - (item2.date ?? 0));

  const record: Record<number, Record<string, EmissionData[]>> = {};
  filteredEmissions.forEach((item) => {
    if (item.date === undefined) return;
    const group = getGroup(item);
    if (group === undefined) return;
    const date = new Date(item.date);
    const year = date.getFullYear();
    const oldYear = record[year];
    if (oldYear === undefined) {
      record[year] = {};
    }
    const oldGroup = record[year][group] ?? [];
    record[year][group] = [...oldGroup, item];
  });

  const tableData: TableData[] = [];
  for (
    let date = startDate;
    endDate.diff(date) > 0;
    date = date.add(1, "year")
  ) {
    const year = date.year();
    const group = groups.map((group) => {
      if (record[year] === undefined || record[year][group.id] === undefined) {
        return 0;
      }
      const total = record[year][group.id]
        .map((item) => item.value)
        .reduce(
          (previousValue, currentValue) => previousValue + currentValue,
          0
        );
      return total;
    });
    const total = group.reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      0
    );

    const previousYear = year - 1;
    const previousGroup = groups.map((group) => {
      if (
        record[previousYear] === undefined ||
        record[previousYear][group.id] === undefined
      ) {
        return 0;
      }
      const total = record[previousYear][group.id]
        .map((item) => item.value)
        .reduce(
          (previousValue, currentValue) => previousValue + currentValue,
          0
        );
      return total;
    });
    const previousTotal = previousGroup.reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      0
    );

    tableData.push({
      year: year,
      group: group,
      total: total,
      previousTotal: previousTotal,
    });
  }
  return tableData;
}

interface Props {
  t: TFunction<[string, string], undefined>;
  title: string;
  info?: string;
  defaultBy: number;
  showByDropdown?: boolean;
  groups: Group[];
  getGroup: (emission: EmissionData) => string | undefined;
  emissions: EmissionData[];
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
}

const EmissionCard = (props: Props) => {
  const [by, setBy] = useState(props.defaultBy);
  const [checkRecord, setCheckRecord] = useState<Record<string, boolean>>(
    () => {
      const record: Record<string, boolean> = {};
      props.groups.forEach((group) => {
        record[group.id] = true;
      });
      return record;
    }
  );
  const t = props.t;

  const onSelectByChange = (e: SelectChangeEvent<number>) => {
    const value = e.target.value;
    if (typeof value === "string") {
      setBy(parseInt(value));
    } else {
      setBy(value);
    }
  };

  const startYear = props.startDate.year();
  const endYear = props.endDate.year();
  const monthNamesSort = [];
  for (
    let date = props.startDate;
    props.endDate.diff(date) > 0;
    date = date.add(1, "month")
  ) {
    monthNamesSort.push(monthNames[date.month()]);
  }

  let body;
  if (by === byChart) {
    const filteredEmissions = props.emissions.filter((item) => {
      const group = props.getGroup(item);
      if (group === undefined) return false;
      return checkRecord[group] ?? false;
    });
    let series;
    let labels;
    if (startYear === endYear) {
      labels = monthNamesSort;
      series = getChartSeries(
        props.groups,
        props.getGroup,
        filteredEmissions,
        props.startDate,
        props.endDate
      );
    } else {
      labels = getNumbersFromNtoM(startYear, endYear).map((item) =>
        item.toString()
      );
      series = getChartSeriesYear(
        props.groups,
        props.getGroup,
        filteredEmissions,
        props.startDate,
        props.endDate
      );
    }
    const checkboxs = props.groups.map((group) => {
      return (
        <ChartCheckbox
          key={group.id}
          text={t(group.title)}
          color={group.colors[1]}
          checked={checkRecord[group.id] ?? false}
          onChange={(_, checked) => {
            checkRecord[group.id] = checked;
            setCheckRecord({ ...checkRecord });
          }}
        />
      );
    });
    body = (
      <Box>
        <Box
          display={"flex"}
          justifyContent={"flex-end"}
          gap={"24px"}
          flexWrap={"wrap"}
        >
          {checkboxs}
        </Box>
        <Box flexShrink={0} width={"100%"} sx={{ overflowX: "auto" }}>
          <StyledBarChart series={series} seriesLabel="tCO2e" labels={labels} />
        </Box>
      </Box>
    );
  } else {
    let data;
    let showYoyChange: boolean;
    if (startYear === endYear) {
      data = getTableSeries(
        props.groups,
        props.getGroup,
        props.emissions,
        props.startDate,
        props.endDate
      );
      showYoyChange = true;
    } else {
      data = getTableSeriesYear(
        props.groups,
        props.getGroup,
        props.emissions,
        props.startDate,
        props.endDate
      );
      showYoyChange = false;
    }
    const groupTitleCell = props.groups.map((item) => {
      return (
        <TableCell key={item.id}>
          <Box display={"inline-flex"} gap={"12px"}>
            <Box width={"24px"} height={"24px"} bgcolor={item.colors[1]} />
            {t(item.title)}
          </Box>
        </TableCell>
      );
    });
    const bodyRow = data.map((item) => {
      const groupCell = item.group.map((group, index) => {
        if (item.total === 0) {
          return (
            <TableCell key={`${item.year}-${item.month}-${index}`}>-</TableCell>
          );
        }
        return (
          <TableCell key={`${item.year}-${item.month}-${index}`}>
            {NumberFormatterUtils.numberFormat((group / item.total) * 100)}%{" "}
            {NumberFormatterUtils.numberFormat(group)} tCO2e
          </TableCell>
        );
      });
      let yoy;
      if (!showYoyChange) {
        yoy = undefined;
      } else if (item.previousTotal === 0) {
        yoy = <TableCell>-</TableCell>;
      } else {
        const trend =
          ((item.total - item.previousTotal) / item.previousTotal) * 100;
        let trendImg = "/img/trend-down.svg";
        if (trend !== undefined && trend >= 0) {
          trendImg = "/img/trend-up.svg";
        }
        yoy = (
          <TableCell>
            <Box display={"flex"}>
              <Typography variant="text2" color="var(--status)">
                {`${NumberFormatterUtils.numberFormat(trend)}%`}
              </Typography>
              <img className="status" src={trendImg} alt="trend" />
            </Box>
          </TableCell>
        );
      }
      return (
        <TableRow key={`${item.year} ${item.month}`}>
          <TableCell>
            {item.month !== undefined ? t(monthNames[item.month]) : ""}{" "}
            {item.year}
          </TableCell>
          {groupCell}
          <TableCell>
            {NumberFormatterUtils.numberFormat(item.total)} tCO2e
          </TableCell>
          {yoy}
        </TableRow>
      );
    });
    body = (
      <TableContainer>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell />
              {groupTitleCell}
              <TableCell>{t("Total")}</TableCell>
              {showYoyChange && <TableCell>{t("YoY-Change")}</TableCell>}
            </TableRow>
            {bodyRow}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <Card variant="outlined" sx={{ borderRadius: "8px", p: "8px" }}>
      <CardContent>
        <Box
          display={"flex"}
          flexWrap={"wrap"}
          justifyContent={"end"}
          mb={"30px"}
          gap={"12px"}
          alignItems={"center"}
        >
          <Typography
            variant="body1"
            flexGrow={1}
            width={{
              xs: "100%",
              sm: "unset",
            }}
          >
            {props.title}
            {props.info && <InfoPopper text={props.info} />}
          </Typography>
          {props.showByDropdown && (
            <img src="/img/setting-5.svg" alt="setting" />
          )}
          {props.showByDropdown && (
            <FormControl variant="standard">
              <Select defaultValue={byChart} onChange={onSelectByChange}>
                <MenuItem value={byChart}>{t("By-Chart")}</MenuItem>
                <MenuItem value={byTable}>{t("By-Table")}</MenuItem>
              </Select>
            </FormControl>
          )}
          {/* <img src="/img/import.svg" alt="import" /> */}
        </Box>
        {body}
      </CardContent>
    </Card>
  );
};

export default EmissionCard;
