import { useTranslation } from "react-i18next";

import { Box, Grid, Typography } from "@mui/material";
import {
  AddEmissionContext,
  EmissionMode,
} from "../../../../contexts/AddEmissionContext";
import InfoPopper from "../../../../components/popper/InfoPopper";
import { useEffect, useState } from "react";
import AddEmissionSectionOne from "./StepTwoSectionOne";
import { DROPDOWN_EMISSION_GROUP } from "../../../../constants/dropdown";
import AddEmissionSectionTwo from "./StepTwoSectionTwo";
import { FormProvider } from "react-hook-form";
import { EmissionGroup } from "../../../../models/EmissionGroup";

const AddEmissionStepTwo = () => {
  const { t } = useTranslation(["common", "input", "overview"], {
    nsMode: "fallback",
  });

  const { loadDataStep2, stepTwoForm, stepOneForm } = AddEmissionContext();

  const [loaded, setLoaded] = useState<boolean>(false);

  const buildExtraSection = () => {
    const currentGroup: string = stepOneForm.getValues("emissionGroup") ?? "";

    const item: EmissionGroup | undefined = DROPDOWN_EMISSION_GROUP.find(
      (e) => e.emissionGroup === currentGroup
    );

    return item?.component ?? null;
  };

  useEffect(() => {
    if (loaded === false) {
      setLoaded(true);
      loadDataStep2(stepOneForm.getValues("emissionGroup"));
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <Typography
          variant="h2"
          color={"var(--dark)"}
          sx={{ marginTop: "24px" }}
        >
          {t(
            DROPDOWN_EMISSION_GROUP.find(
              (i) => i.emissionGroup === stepOneForm.getValues("emissionGroup")
            )?.groupEn ?? ""
          )}

          <InfoPopper
            text={t(
              DROPDOWN_EMISSION_GROUP.find(
                (i) =>
                  i.emissionGroup === stepOneForm.getValues("emissionGroup")
              )?.description?.toString() ?? ""
            )}
          />
        </Typography>

        <Grid container columnSpacing={"20px"} rowGap={"20px"}>
          <AddEmissionSectionOne />

          <FormProvider {...stepTwoForm}>
            {buildExtraSection()}

            {/* {emissionMode === EmissionMode.AddEmission ? (
              <Grid item xs={0} md={12}>
                <Box height={"72px"}></Box>
              </Grid>
            ) : null} */}
          </FormProvider>
          <AddEmissionSectionTwo />
        </Grid>
      </Box>
    </>
  );
};

export default AddEmissionStepTwo;
