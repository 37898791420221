import { Avatar, Box, Stack, Typography } from "@mui/material";
import { AddEmissionContext } from "../../contexts/AddEmissionContext";
import { useTranslation } from "react-i18next";
import NumberFormatterUtils from "../../utils/numberFormatterUtils";

const ScopeSummary = () => {
  const { totalActivitiesListNumber, sumScopes } = AddEmissionContext();

  const { t } = useTranslation(["common", "overview"], {
    nsMode: "fallback",
  });

  return (
    <Box width={"100%"} bgcolor={"var(--gray3)"} borderRadius={"8px"}>
      <Stack
        padding={"10px 14px"}
        justifyContent={"space-between"}
        color={"black"}
        rowGap={1.5}
        columnGap={2}
        direction="row"
        useFlexGap
        flexWrap="wrap"
      >
        <DataSummary
          title={t("Records")}
          info={`${totalActivitiesListNumber} ${t("Records")}`}
        />
        <DataSummary
          title={t("Emission")}
          info={`${NumberFormatterUtils.numberFormat(
            sumScopes.sum ?? 0
          )} tCO2e`}
        />
        <DataSummary
          title={t("Scope-1")}
          info={`${NumberFormatterUtils.numberFormat(
            sumScopes.s1 / 1000
          )} tCO2e`}
          icon={"/img/scope1.svg"}
        />
        <DataSummary
          title={t("Scope-2")}
          info={`${NumberFormatterUtils.numberFormat(
            sumScopes.s2 / 1000
          )} tCO2e`}
          icon={"/img/scope2.svg"}
        />
        <DataSummary
          title={t("Scope-3")}
          info={`${NumberFormatterUtils.numberFormat(
            sumScopes.s3 / 1000
          )} tCO2e`}
          icon={"/img/scope3.svg"}
        />

        <DataSummary
          title={t("Outside-of-Scope")}
          info={`${NumberFormatterUtils.numberFormat(
            sumScopes.s0 / 1000
          )} tCO2e`}
        />
      </Stack>
    </Box>
  );
};

export default ScopeSummary;

interface Props {
  title: string;
  info: string;
  icon?: string;
}

const DataSummary = (props: Props) => {
  return (
    <Box display={"flex"} flexDirection={"row"} gap={1}>
      {props.icon && (
        <Avatar src={props.icon} sx={{ width: "33px", height: "33px" }} />
      )}
      <Typography fontWeight={500} alignContent={"center"} variant="body1">
        {props.title} :
      </Typography>
      <Typography
        alignContent={"center"}
        variant="body1"
        color={"var(--gray1)"}
      >
        {props.info}
      </Typography>
    </Box>
  );
};
