import styled from "@emotion/styled";

export const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

type TableStyle = "dark" | "light";

export const tableStyle = (them?: TableStyle) => ({
  ".css-d8v667-MuiButtonBase-root-MuiMenuItem-root-MuiTablePagination-menuItem.Mui-selected":
    { backgroundColor: "var(--dark) !important" },
  ".MuiDataGrid-iconButtonContainer": {
    visibility: "visible",
    color: "var(--white)",
  },
  ".MuiDataGrid-sortIcon": {
    opacity: "inherit !important",
    color: them === "light" ? "var(--gray4) " : "var(--white)",
  },
  ".MuiDataGrid-columnHeader": {
    backgroundColor: them === "light" ? "var(--gray8)" : "var(--dark)",
    color: them === "light" ? "var(--dark)" : "var(--white)",
    outline: "solid transparent 0px !important",
  },
  ".MuiDataGrid-cell": {
    alignContent: "center",
    lineHeight: "20px",
    position: "relative",
  },
  ".MuiTableRow-root.Mui-selected:hover": {
    backgroundColor: "var(--green) !important",
  },
  ".MuiDataGrid-cell:focus": {
    outline: "solid #0B9485 0px !important",
  },
  "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
    outline: "none !important",
  },
  ".MuiDataGrid-cell:focus-within": {
    outline: "0px !important",
  },
  ".css-ptiqhd-MuiSvgIcon-root": {
    width: "24px",
    height: "24px",
  },
  ".css-dwjt6a-MuiButtonBase-root-MuiIconButton-root": {
    padding: "0 !important",
  },
  ".css-fp9tcu-MuiButtonBase-root-MuiIconButton-root": {
    padding: "0 !important",
  },
  border: "0px",
  fontSize: "14px",
});
