import { Modal, Box, Typography, Button } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import CustomSelect, { MenuData } from "../../components/input/CustomSelect";
import { useCallback, useEffect, useState } from "react";
import CustomInput from "../../components/input/CustomInput";
import { useTranslation } from "react-i18next";
import CustomModal from "../../components/modal/CustomModal";

const assetStatusMenuItem: MenuData[] = [
  {
    nameTH: "เป็นเจ้าของและใช้งานเอง",
    nameEN: "Owned and used",
    value: "owned and used",
  },
  {
    nameTH: "เช่าจากผู้อื่น",
    nameEN: "Leased in",
    value: "lease in",
  },
  {
    nameTH: "เป็นเจ้าของและให้ผู้อื่นเช่า",
    nameEN: "Owned and leased out",
    value: "owned and leased out",
  },
  {
    nameTH: "ไม่ใช้งาน",
    nameEN: "Inactive",
    value: "inactive",
  },
];

interface Props {
  editItem: any | undefined;
  open: boolean;
  onClose: () => void;
  currentOrganization?: string;
  organizationsMenuItem: { nameTH: string; nameEN: string; value: string }[];
  emissionGroup: { nameTH: string; nameEN: string; value: string }[];
  selectedSubOrganization?: string;
  subOrganizationsMenuItem: { nameTH: string; nameEN: string; value: string }[];
  assetType: { nameTH: string; nameEN: string; value: string }[];
  onAddClick: (
    organization?: string,
    emissionGroup?: string,
    subOrganization?: string,
    assetType?: string,
    assetName?: string,
    assetStatus?: string
  ) => void;
  onEditClick: (
    assetType?: string,
    assetName?: string,
    assetStatus?: string
  ) => void;
}

const AddAssetModal = (props: Props) => {
  const { t } = useTranslation(["common", "input"], { nsMode: "fallback" });
  const [organization, setOrganization] = useState<string>();
  const [emissionGroup, setEmissionGroup] = useState<string>();
  const [subOrganization, setSubOrganization] = useState<string>();
  const [assetType, setAssetType] = useState<string>();
  const [assetName, setAssetName] = useState<string>();
  const [assetStatus, setAssetStatus] = useState<string>();

  const clearData = () => {
    setOrganization(props.currentOrganization);
    setEmissionGroup((props.editItem?.emissionGroup as string)?.toLowerCase());
    if (props.selectedSubOrganization !== undefined) {
      setSubOrganization(props.selectedSubOrganization);
    } else if (props.subOrganizationsMenuItem.length === 1) {
      setSubOrganization(props.subOrganizationsMenuItem[0].value);
    } else {
      setSubOrganization(props.editItem?.subOrganization.id);
    }
    setAssetType(props.editItem?.assetType);
    setAssetName(props.editItem?.name);
    setAssetStatus(props.editItem?.status ?? assetStatusMenuItem[0].value);
  };

  useEffect(() => {
    clearData();
  }, [props.editItem]);

  useEffect(() => {
    setOrganization(props.currentOrganization);
  }, [props.currentOrganization]);

  useEffect(() => {
    if (props.selectedSubOrganization !== undefined) {
      setSubOrganization(props.selectedSubOrganization);
    } else if (props.subOrganizationsMenuItem.length === 1) {
      setSubOrganization(props.subOrganizationsMenuItem[0].value);
    }
  }, [props.selectedSubOrganization, props.subOrganizationsMenuItem]);

  const onAdd = () => {
    if (props.editItem === undefined) {
      props.onAddClick(
        organization,
        emissionGroup,
        subOrganization,
        assetType,
        assetName,
        assetStatus
      );
    } else {
      props.onEditClick(assetType, assetName, assetStatus);
    }
    clearData();
  };

  const onClose = () => {
    props.onClose();
    clearData();
  };

  return (
    <CustomModal
      header={
        <Typography variant="h1">
          {props.editItem === undefined ? t("Add-Asset") : t("Edit-Asset")}
        </Typography>
      }
      body={
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={"44px"}
          width={"100%"}
        >
          <Grid2
            container
            rowSpacing={"20px"}
            columnSpacing={"40px"}
            disableEqualOverflow
          >
            <Grid2 xs={6}>
              <CustomSelect
                label={t("Organization")}
                value={organization}
                listMenuItem={props.organizationsMenuItem}
                required
                disabled
                handleValeOnChange={(item) =>
                  setOrganization(item.target.value)
                }
              />
            </Grid2>
            <Grid2 xs={6}>
              <CustomSelect
                label={t("Emission-Group")}
                value={emissionGroup ?? ""}
                listMenuItem={props.emissionGroup}
                required
                disabled={props.editItem !== undefined}
                handleValeOnChange={(item) =>
                  setEmissionGroup(item.target.value)
                }
              />
            </Grid2>
            <Grid2 xs={6}>
              <CustomSelect
                label={t("Sub-Organizations")}
                value={subOrganization ?? ""}
                listMenuItem={props.subOrganizationsMenuItem}
                required
                disabled={
                  props.selectedSubOrganization !== undefined ||
                  props.editItem !== undefined
                }
                handleValeOnChange={(item) =>
                  setSubOrganization(item.target.value)
                }
              />
            </Grid2>
            <Grid2 xs={6}>
              <CustomInput
                label={t("Asset-Type")}
                value={assetType}
                required
                handleOnChange={(item) => setAssetType(item.target.value)}
              />
            </Grid2>
            <Grid2 xs={6}>
              <CustomInput
                label={t("Asset-Name")}
                value={assetName}
                required
                handleOnChange={(item) => setAssetName(item.target.value)}
              />
            </Grid2>
            <Grid2 xs={6}>
              <CustomSelect
                label={t("Asset-Status")}
                value={assetStatus}
                listMenuItem={assetStatusMenuItem}
                required
                handleValeOnChange={(item) => setAssetStatus(item.target.value)}
              />
            </Grid2>
          </Grid2>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={onAdd}
          >
            {props.editItem === undefined ? t("Add") : t("Save")}
          </Button>
        </Box>
      }
      open={props.open}
      onClose={onClose}
    />
  );
};

export default AddAssetModal;
