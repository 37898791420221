import { Button, ButtonGroup, styled } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { LayoutContext } from "../../contexts/LayoutContext";

const AccountMenu = () => {
  const { setOpenSignOut } = useContext(LayoutContext);
  const { t } = useTranslation(["common", "account"], { nsMode: "fallback" });
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const StyleButton = styled(Button)({
    justifyContent: "start",
    backgroundColor: "var(--gray6)",
    border: "1px solid var(--gray5) !important",
    color: "var(--gray4)",
    padding: "16px",
    "&.active": {
      borderLeft: "4px solid var(--green) !important",
      backgroundColor: "var(--white)",
      color: "var(--dark)",
    },
  });

  return (
    <ButtonGroup orientation="vertical" fullWidth>
      <StyleButton
        className={pathname.includes("profile") ? "active" : ""}
        onClick={() => navigate("/account/profile")}
      >
        {t("User-Profile")}
      </StyleButton>
      <StyleButton
        className={pathname.includes("password") ? "active" : ""}
        onClick={() => navigate("/account/reset-password")}
      >
        {t("Password")}
      </StyleButton>
      {/* <StyleButton onClick={() => setOpenSignOut(true)}>
        {t("Sign-out")}
      </StyleButton> */}
    </ButtonGroup>
  );
};

export default AccountMenu;
