import {
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import InfoPopper from "../popper/InfoPopper";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";

export interface MenuData {
  nameTH: string;
  nameEN: string;
  value: any;
  disabled?: boolean;
}

interface Props {
  label: string;
  info?: string;
  value: any;
  placeholder?: string;
  listMenuItem: MenuData[];
  required?: boolean;
  error?: boolean;
  errorMessage?: string;
  handleValeOnChange?: (e: SelectChangeEvent) => void;
  resetValue?: () => void;
  disabled?: boolean;
  ref?: any;
}

const CustomSelect = (props: Props) => {
  const { t, i18n } = useTranslation(["common", "input"], {
    nsMode: "fallback",
  });

  return (
    <FormControl fullWidth>
      <Typography variant="h3" color={"var(--dark)"}>
        {props.label}

        {props.required === true ? (
          <span style={{ color: "var(--red)" }}> * </span>
        ) : (
          <span> ({t("Optional")}) </span>
        )}
        {props.info && <InfoPopper text={props.info} />}
      </Typography>

      <Box position={"relative"}>
        <Select
          displayEmpty
          fullWidth
          disabled={props.disabled}
          size="small"
          value={props.value}
          onChange={props.handleValeOnChange}
          error={props.error}
          ref={props.ref}
          sx={{
            margin: "8px 0",
            backgroundColor: props.disabled ? "var(--gray2)" : "null",
          }}
        >
          {props.placeholder && (
            <MenuItem disabled value="">
              <span
                style={{
                  color: "var(--gray4)",
                }}
              >
                {props.placeholder}
              </span>
            </MenuItem>
          )}

          {props.listMenuItem.map((m) => (
            <MenuItem key={`${m.value}`} value={m.value} disabled={m.disabled}>
              {i18n.language === "th" ? m.nameTH : m.nameEN}
            </MenuItem>
          ))}
        </Select>

        {props.resetValue && props.value !== "" ? (
          <IconButton
            aria-label="close"
            onClick={props.resetValue}
            sx={{
              position: "absolute",
              right: "32px",
              top: "50%",
              transform: "translate(0, -50%)",
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </Box>

      {props.error && (
        <Typography variant="text2" style={{ color: "var(--red)" }}>
          {props.errorMessage}
        </Typography>
      )}
    </FormControl>
  );
};

export default CustomSelect;
