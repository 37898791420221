import { Box } from "@mui/material";
import { Logo } from "../icon/logo";

interface Props {
  logoType: string;
  logoWidth: string;
  marginTop: string;
}

const LogoGradient = (props: Props) => {
  return (
    <Box
      display={"flex"}
      flexShrink={0}
      width={"100%"}
      height={"154px"}
      justifyContent={"center"}
      borderRadius={"0 20px 0 0"}
      sx={{
        backgroundImage: "linear-gradient(#14B254, #0074B5)",
      }}
    >
      <Box margin={"auto"} top={0} left={0} display={"flex"}>
        <Logo
          type={props.logoType}
          width={props.logoWidth}
          style={{
            marginTop: props.marginTop,
            transition: ".3s",
            width: "100%",
          }}
        />
      </Box>
    </Box>
  );
};

export default LogoGradient;
