import { createContext } from "react";
import Emission, { EmissionData } from "../models/Emission";
import dayjs from "dayjs";
import { SubOrganizations } from "../models/SubOrganization";

export interface DashbroadState {
    emissions: EmissionData[];
    filteredEmissions: EmissionData[];
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;
}

export const DashbroadContext = createContext<DashbroadState>({
    emissions: [],
    filteredEmissions: [],
    startDate: dayjs(),
    endDate: dayjs(),
});
