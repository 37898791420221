import { Navigate, createBrowserRouter } from "react-router-dom";
import Dashboard from "./pages/dashboard";
import Error from "./pages/error";
import LoginPage from "./pages/login";
import SelectOrganization from "./pages/select-organization";
import ScopeOne from "./pages/dashboard/scope1";
import ScopeTwo from "./pages/dashboard/scope2";
import ScopeThree from "./pages/dashboard/scope3";
import DashboardLayout from "./components/dashboard/DashboardLayout";
import Others from "./pages/dashboard/others";
import AddEmission from "./pages/emission";
import BusinessStructure from "./pages/business-structure";
import ProtectedRoutes from "./utils/protectedRoutes";
import Assets from "./pages/business-structure/Assets";
import OrganizationProfile from "./pages/business-structure/OrganizationProfile";
import BusinessProfile from "./pages/business-structure/BusinessProfile";
import MonthlyActivities from "./pages/setting/activity-templates/ActivityTemplates";
import Account from "./pages/account";
import Profile from "./pages/account/Profile";
import { AccessControl } from "./pages/setting/access-control/AccessControl";
import ActivatePage from "./pages/activate/ActivatePage";
import ResetPassword from "./pages/account/ResetPassword";
import { ImportData } from "./pages/import-data/ImportData";
import { ReportPage } from "./pages/report/Report";

// use ProtectedRoutes for page is required auth

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/overview/total-emissions" replace />,
  },
  {
    path: "/overview",
    errorElement: <Error />,
    element: (
      <ProtectedRoutes>
        <DashboardLayout />
      </ProtectedRoutes>
    ),
    children: [
      {
        index: true,
        element: <Navigate to="/overview/total-emissions" replace />,
      },
      {
        path: "/overview/total-emissions",
        element: <Dashboard />,
      },
      {
        path: "/overview/scope-1",
        element: <ScopeOne />,
      },
      {
        path: "/overview/scope-2",
        element: <ScopeTwo />,
      },
      {
        path: "/overview/scope-3",
        element: <ScopeThree />,
      },
      {
        path: "/overview/other",
        element: <Others />,
      },
    ],
  },
  {
    path: "/emission",
    children: [
      {
        index: true,
        element: <Navigate to="/emission/add" replace />,
      },
      {
        path: "/emission/add",
        element: (
          <ProtectedRoutes>
            <AddEmission />
          </ProtectedRoutes>
        ),
      },
      {
        path: "/emission/import-data",
        element: (
          <ProtectedRoutes>
            <ImportData />
          </ProtectedRoutes>
        ),
      },
      // {
      //   path: "/input/eco-efficiency",
      //   element: <ScopeOne />,
      // },
    ],
  },
  {
    path: "/report",
    element: (
      <ProtectedRoutes>
        <ReportPage />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/setting",
    children: [
      {
        index: true,
        element: <Navigate to="/setting/activities-template" replace />,
      },
      {
        path: "/setting/activities-template",
        element: (
          <ProtectedRoutes>
            <MonthlyActivities />
          </ProtectedRoutes>
        ),
      },
      {
        path: "/setting/access-control",
        element: (
          <ProtectedRoutes>
            <AccessControl />
          </ProtectedRoutes>
        ),
      },
      {
        path: "/setting/business-structure",
        element: (
          <ProtectedRoutes>
            <BusinessStructure />
          </ProtectedRoutes>
        ),
        children: [
          {
            index: true,
            element: (
              <Navigate to="/setting/business-structure/profile" replace />
            ),
          },
          {
            path: "/setting/business-structure/profile",
            element: <BusinessProfile />,
          },
          {
            path: "/setting/business-structure/asset",
            element: <Assets />,
          },
        ],
      },
    ],
  },

  // {
  //   path: "/data",
  //   element: (
  //     <ProtectedRoutes>
  //       <Navigate to="/data/emission-factors" />
  //     </ProtectedRoutes>
  //   ),
  // },
  // {
  //   path: "/data",
  //   children: [
  //     {
  //       path: "/data/emission-factors",
  //       element: (
  //         <ProtectedRoutes>
  //           <ScopeOne />
  //         </ProtectedRoutes>
  //       ),
  //     },
  //   ],
  // },
  {
    path: "/account",
    element: (
      <ProtectedRoutes>
        <Account />
      </ProtectedRoutes>
    ),
    children: [
      {
        index: true,
        element: <Navigate to="/account/profile" replace />, // <-- redirect
      },
      {
        path: "/account/profile",
        element: (
          <ProtectedRoutes>
            <Profile />
          </ProtectedRoutes>
        ),
      },
      {
        path: "/account/reset-password",
        element: (
          <ProtectedRoutes>
            <ResetPassword />
          </ProtectedRoutes>
        ),
      },
    ],
  },
  // {
  //   path: "/business-structure",
  //   element: (
  //     <ProtectedRoutes>
  //       <BusinessStructure />
  //     </ProtectedRoutes>
  //   ),
  //   children: [
  //     {
  //       index: true,
  //       element: <Navigate to="/business-structure/profile" replace />,
  //     },
  //     {
  //       path: "/business-structure/profile",
  //       element: <BusinessProfile />,
  //     },
  //     {
  //       path: "/business-structure/asset",
  //       element: <Assets />,
  //     },
  //   ],
  // },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/select-organization",
    element: (
      <ProtectedRoutes>
        <SelectOrganization />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/activate",
    element: <ActivatePage />,
  },
]);
