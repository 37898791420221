import { Box } from "@mui/material";
import { GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import AddEmissionForm from "../popup-add-emission/PopupAddEmission";

export const QuickSearchToolbar = () => {
  // const handleExport = () => {
  //   apiRef.current.exportDataAsCsv({ fileName: "emission_report" });
  // };

  return (
    <Box
      marginBottom={"40px"}
      display={"flex"}
      flexDirection={{ xs: "column-reverse", sm: "row" }}
      justifyContent={"space-between"}
      alignItems={"center"}
      gap={4}
    >
      <GridToolbarContainer
        sx={{
          padding: 0,
          width: { xs: "100%", sm: "auto" },
        }}
      >
        <GridToolbarQuickFilter
          sx={{
            border: "1px solid var(--gray1)",
            borderRadius: "4px",
            margin: 0,
            padding: 0,
            width: "100%",
          }}
          inputProps={{
            style: {
              padding: "0px",
            },
          }}
          InputProps={{
            disableUnderline: true,
            sx: {
              padding: "9px",
              margin: 0,
              width: "100%",
            },
          }}
        />
      </GridToolbarContainer>

      <GridToolbarContainer
        sx={{
          padding: 0,
          width: { xs: "100%", sm: "auto" },
        }}
      >
        <AddEmissionForm />
        {/* <Button
            variant="outlined"
            color="secondary"
            fullWidth
            startIcon={<img src="/img/frame.svg" alt="" />}
            onClick={() => handleExport()}
          >
            {t("Export")}
          </Button> */}
      </GridToolbarContainer>
    </Box>
  );
};
