import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useEffect, useState } from "react";
import { AddEmissionContext } from "../../../../contexts/AddEmissionContext";
import { useAuth } from "../../../../contexts/UserContext";
import { Assets } from "../../../../models/Asset";
import { EmissionGroup } from "../../../../models/EmissionGroup";
import ArrayUtils from "../../../../utils/arrayUtils";
import { CustomAutoComplete } from "../../../../components/input/CustomAutoComplete";
import { InputReactHookForm } from "../../../../components/input/InputReactHookForm";

const AddEmissionStepOne: React.FC = () => {
  const { t } = useTranslation(["common", "overview", "input"], {
    nsMode: "fallback",
  });

  const user = useAuth();

  const { setValue, control, watch } = useFormContext();

  const subOrganizations: string = watch("subOrganizations");

  const emissionGroup: string = watch("emissionGroup");

  const assetType: string = watch("assetType");

  const assetName: string = watch("assetName");

  const { emissionGroupList, subOrganizationList, assetList } =
    AddEmissionContext();

  const [emissionGroupForSelect] = useState<EmissionGroup[]>(emissionGroupList);

  const [mode, setMode] = useState<any | null>(null);

  const resetDependentFields = (fields: any[]) => {
    fields.forEach((field) => {
      setValue(field, null);
    });
  };

  const beforFilterSelect = (event: any) => {
    if (event.target.value !== null) {
      setMode(event);
    } else {
      setMode(event);
    }

    const { name } = event.target;

    switch (name) {
      case "subOrganizations":
        resetDependentFields(["emissionGroup", "assetType", "assetName"]);
        break;
      case "emissionGroup":
        resetDependentFields(["assetType", "assetName"]);
        break;
      default:
        break;
    }
  };

  const filterAssetType = (): Assets[] => {
    const subOrganizationsValue = subOrganizations ?? "";

    const emissionGroupValue = emissionGroup ?? "";

    const assetRef = assetName ?? "";

    let temp = [...assetList];

    if (subOrganizationsValue !== "") {
      temp = temp.filter((i) => i.suborganization === subOrganizationsValue);
    }

    if (emissionGroupValue !== "") {
      temp = temp.filter((i) => i.emissionGroup === emissionGroup);
    }

    if (assetRef !== "") {
      temp = temp.filter((i) => i._id === assetRef);
    }

    const result = ArrayUtils.filterArrayUniqueByKey("assetType", [...temp]);

    return result;

    // setAssetTypeForSelect(
    //   ArrayUtils.filterArrayUniqueByKey("assetType", [...temp])
    // );

    // if (mode.target.name === "assetType" && mode.target.value === null) {
    //   return;
    // }

    // if (temp.length === 1) {
    //   setValue("assetType", temp[0].assetType);
    // }
  };

  const filterAssetName = (): Assets[] => {
    const subOrganizationsValue = subOrganizations ?? "";

    const emissionGroupValue = emissionGroup ?? "";

    const assetTypeValue = assetType ?? "";

    let temp = [...assetList];

    if (subOrganizationsValue !== "") {
      temp = temp.filter((i) => i.suborganization === subOrganizationsValue);
    }

    if (emissionGroupValue !== "") {
      temp = temp.filter((i) => i.emissionGroup === emissionGroup);
    }

    if (assetTypeValue !== "") {
      temp = temp.filter((i) => {
        return i.assetType === assetType;
      });
    }

    const result = [...temp];

    return result;
  };

  const autoSelect = (assetType: Assets[], assetName: Assets[]) => {
    if (mode) {
      const { name, value } = mode.target;

      if (value === null) {
        return;
      }

      if (assetType.length === 1) {
        setValue("assetType", assetType[0].assetType, {
          shouldValidate: true,
        });
      }

      if (assetName.length === 1) {
        setValue("assetName", assetName[0]._id, {
          shouldValidate: true,
        });
      }
    }
  };

  const buildSelect = () => {
    const assetType = filterAssetType();

    const assetName = filterAssetName();

    autoSelect(assetType, assetName);
  };

  useEffect(() => {
    if (mode === null) {
      if (subOrganizationList.length === 1) {
        setValue("subOrganizations", subOrganizationList[0].id);
      }

      setValue(
        "organization",
        user.organization != null ? user.organization.name : ""
      );

      return;
    }

    buildSelect();

    if (subOrganizations) {
      const findResult = subOrganizationList.find((i) => {
        return subOrganizations === i.id;
      });
      setValue(
        "location",
        `${findResult?.location?.address ?? ""} ${
          findResult?.location?.district ?? ""
        } ${findResult?.location?.city ?? ""}`.trim()
      );
    }
  }, [subOrganizations, emissionGroup, assetType, assetName]);

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <InputReactHookForm
          label={t("Organization")}
          info={t("Organization")}
          name="organization"
          control={control}
          placeholder=""
          defaultValue={""}
          disabled
          required
        />

        <CustomAutoComplete
          required
          rules={{
            required: t("Please-Select-Organization"),
            onChange(event) {
              beforFilterSelect(event);
            },
          }}
          name="subOrganizations"
          control={control}
          label={t("Sub-Organizations")}
          info={t("Sub-Organizations")}
          placeholder={t("Select-Param", {
            param: t("Sub-Organizations"),
          })}
          listMenuItem={subOrganizationList.map((m) => ({
            value: m.id,
            nameEN: t(`${m.name}`),
            nameTH: t(`${m.name}`),
            disabled: false,
          }))}
        />

        <InputReactHookForm
          label={t("Location")}
          info={t("Location")}
          name="location"
          control={control}
          placeholder=""
          defaultValue={""}
          disabled
          required
        />

        <CustomAutoComplete
          required
          rules={{
            required: t("form-reqired-error", {
              param: t("Emission-Group"),
            }),
            onChange(event) {
              beforFilterSelect(event);
            },
          }}
          name="emissionGroup"
          control={control}
          label={t("Emission-Group")}
          info={t("Emission-Group")}
          placeholder={t("Select-Param", {
            param: t("Emission-Group"),
          })}
          listMenuItem={emissionGroupForSelect.map((m) => ({
            value: m.emissionGroup,
            nameEN: t(`${m.groupEn}`),
            nameTH: t(`${m.groupEn}`),
            disabled: m.disabled ? true : false,
          }))}
          disabled={!subOrganizations ? true : false}
        />
        <CustomAutoComplete
          required
          rules={{
            required: t("form-reqired-error", {
              param: t("Asset-Type"),
            }),
            onChange(event) {
              beforFilterSelect(event);
            },
          }}
          name="assetType"
          control={control}
          label={t("Asset-Type")}
          info={t("Asset-Type-Description")}
          placeholder={t("Select-Param", {
            param: t("Asset-Type"),
          })}
          listMenuItem={filterAssetType().map((m) => ({
            value: m.assetType,
            nameEN: t(`${m.assetType}`),
            nameTH: t(`${m.assetType}`),
            disabled: false,
          }))}
          disabled={!subOrganizations ? true : false}
        />
        <CustomAutoComplete
          name="assetName"
          rules={{
            required: t("form-reqired-error", {
              param: t("Asset-Name"),
            }),
            onChange(event) {
              beforFilterSelect(event);
            },
          }}
          required
          control={control}
          label={t("Asset-Name")}
          info={t("Asset-Name-Description")}
          placeholder={t("Select-Param", {
            param: t("Asset-Name"),
          })}
          listMenuItem={filterAssetName().map((m) => ({
            value: m._id,
            nameEN: t(`${m.name}`),
            nameTH: t(`${m.name}`),
            disabled: false,
          }))}
          disabled={!subOrganizations ? true : false}
        />
      </Box>
    </>
  );
};

export default AddEmissionStepOne;
