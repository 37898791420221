import { Box, Grid, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";

export interface TabMenuData {
  name: string;
  pathname: string;
  disabled?: boolean;
}

interface Props {
  tabMenuData: TabMenuData[];
}

export const TabMenu = (props: Props) => {
  const { pathname } = useLocation();

  const { t } = useTranslation(["common", "input", "account", "organization"], {
    nsMode: "fallback",
  });

  const firstItemStyle = {
    borderRadius: "4px 0px 0px 4px",
  };

  const midItemStyle = {
    borderRadius: 0,
  };

  const lastItemStyle = {
    borderRadius: "0px 4px 4px 0px",
  };

  return (
    <>
      <Box sx={{ padding: "24px 40px" }}>
        <Grid
          container
          display={"flex"}
          flexDirection={{
            xs: "column",
            sm: "row",
          }}
        >
          {props.tabMenuData.map((e, index) => (
            <Grid flex="1" key={e.name}>
              {e.disabled ? (
                <Button
                  type="button"
                  variant="contained"
                  color={pathname === e.pathname ? "green" : "white"}
                  disableElevation
                  disabled={e.disabled}
                  sx={{
                    width: "100%",
                    height: "100%",
                    padding: "9px 0px",
                    borderRadius:
                      index === 0
                        ? firstItemStyle
                        : index === props.tabMenuData.length - 1
                        ? lastItemStyle
                        : midItemStyle,
                  }}
                >
                  <Typography
                    fontWeight={500}
                    alignContent={"center"}
                    variant="h3"
                  >
                    {t(e.name)}
                  </Typography>
                </Button>
              ) : (
                <Link to={e.pathname}>
                  <Button
                    type="button"
                    variant="contained"
                    color={pathname === e.pathname ? "green" : "white"}
                    disableElevation
                    sx={{
                      width: "100%",
                      height: "100%",
                      padding: "9px 0px",
                      borderRadius:
                        index === 0
                          ? firstItemStyle
                          : index === props.tabMenuData.length - 1
                          ? lastItemStyle
                          : midItemStyle,
                    }}
                  >
                    <Typography
                      fontWeight={500}
                      alignContent={"center"}
                      variant="h3"
                    >
                      {t(e.name)}
                    </Typography>
                  </Button>
                </Link>
              )}
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};
