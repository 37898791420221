import {
  Avatar,
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import CustomInput from "../../components/input/CustomInput";
import { stringAvatar } from "../../utils/avatar";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { useAccount } from "../../contexts/AccountContext";
import alertService from "../../components/alert/alertService";
import { useForm } from "react-hook-form";
import imageCompression from "browser-image-compression";
import { InputReactHookForm } from "../../components/input/InputReactHookForm";
import { LayoutContext } from "../../contexts/LayoutContext";

export type ProfileFormValues = {
  name: string;
  displayName: string;
  jobTitle: string;
  phoneNumber: string;
  userProfile: string;
};

const Profile = () => {
  const { user, updateUser } = useAccount();

  const [userProfile, setUserProfile] = useState<string | null>(
    user?.userProfile ?? null
  );

  const { t } = useTranslation(["common", "account"], { nsMode: "fallback" });

  const { handleSubmit, control, watch, setValue } = useForm<ProfileFormValues>(
    {
      mode: "all",
      reValidateMode: "onChange",
      shouldUnregister: false,
    }
  );

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        const options = {
          maxSizeMB: 0.1, // 0.1 MB = 100 KB
          maxWidthOrHeight: 112, // Resize image if larger than this
          useWebWorker: true, // Use web worker for compression
        };
        const compressedFile = await imageCompression(file, options);
        const imageDataUrl = await imageCompression.getDataUrlFromFile(
          compressedFile
        );
        setUserProfile(imageDataUrl.split(",")[1]);
      } catch (e) {
        alertService.error("Failed to resize image");
      }
    }
  };

  const { setProfile } = useContext(LayoutContext);

  const onSubmit = async (values: ProfileFormValues) => {
    // const result = await updateUser(
    //   watch("name") ?? null,
    //   watch("displayName") ?? null,
    //   watch("phoneNumber") ?? null,
    //   watch("jobTitle") ?? null,
    //   userProfile ? userProfile : null
    // );
    // if (result) {
    //   setTimeout(() => {
    //     window.location.reload();
    //   }, 1500);
    // }

    updateUser(
      watch("name") ?? null,
      watch("displayName") ?? null,
      watch("phoneNumber") ?? null,
      watch("jobTitle") ?? null,
      userProfile ? userProfile : null
    );

    setProfile(userProfile ? userProfile : undefined);
  };

  useEffect(() => {
    setValue("displayName", user?.displayName ?? "");

    setValue("name", user?.fullname ?? "");

    setValue("jobTitle", user?.jobTitle ?? "");

    setValue("phoneNumber", user?.phoneNumber ?? "");

    try {
      setUserProfile(user?.userProfile ?? null);
    } catch (error) {
      setUserProfile(null);
    }
  }, [user]);

  return (
    <Paper sx={{ padding: "20px" }} elevation={0} variant="outlined">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            height: "100%",
            width: "100%",
            overflow: "auto",
          }}
          display={"flex"}
          flexDirection={"column"}
          gap={"40px"}
        >
          <Box
            flex={1}
            sx={{
              overflow: "auto",
              height: "100%",
              width: "100%",
            }}
          >
            <Grid2
              container
              rowSpacing={"20px"}
              columnSpacing={"40px"}
              sx={{
                width: "100%",
              }}
            >
              <Grid2 direction={"row"} gap={"20px"} xs={12} display={"flex"}>
                <Box>
                  <IconButton
                    component="label"
                    sx={{
                      padding: "0",
                    }}
                  >
                    <Box
                      height={"40px"}
                      width={"40px"}
                      bgcolor={"var(--green)"}
                      style={{
                        zIndex: 1,
                        position: "absolute",
                        pointerEvents: "none",
                        bottom: 0,
                        right: 0,
                        borderRadius: "50%",
                      }}
                    >
                      <img
                        src="/img/edit-white.svg"
                        alt="edit-icon"
                        height={"24px"}
                        width={"24px"}
                        style={{
                          color: "white",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      />
                    </Box>

                    {userProfile ? (
                      <img
                        src={
                          userProfile
                            ? `data:image/png;base64,${userProfile}`
                            : ""
                        }
                        alt="resized"
                        style={{
                          width: "112px",
                          height: "112px",
                          borderRadius: "50%",
                        }}
                      />
                    ) : (
                      <Avatar
                        {...stringAvatar(user?.displayName ?? "")}
                        sx={{
                          fontSize: "56px",
                          width: "112px",
                          height: "112px",
                          zIndex: 0,
                        }}
                      />
                    )}
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      hidden
                    />
                  </IconButton>
                </Box>

                <Stack gap={"8px"} justifyContent={"center"}>
                  <Typography variant="h3">{t("Profile-Logo")}</Typography>
                  <Typography variant="text1" color={"var(--gray4)"}>
                    {t("Profile-Photo-Description")}
                  </Typography>
                </Stack>
              </Grid2>
              <Grid2 xs={12} md={6}>
                <InputReactHookForm
                  required
                  label={t("user-display-name")}
                  placeholder={t("user-display-name")}
                  name="displayName"
                  control={control}
                  rules={{
                    required: t("form-reqired-error", {
                      param: t("user-display-name"),
                    }),
                  }}
                  defaultValue=""
                />
              </Grid2>
              <Grid2 xs={12} md={6}>
                <InputReactHookForm
                  label={t("Name")}
                  placeholder={t("Name")}
                  name="name"
                  control={control}
                  defaultValue=""
                />
              </Grid2>
              <Grid2 xs={12} md={6}>
                <InputReactHookForm
                  label={t("Job-Title")}
                  placeholder={t("Job-Title")}
                  name="jobTitle"
                  control={control}
                  defaultValue=""
                />
              </Grid2>
              <Grid2
                xs={12}
                md={6}
                display={{ xs: "none", md: "grid" }}
              ></Grid2>
              <Grid2 xs={12} md={6}>
                <CustomInput
                  label={t("Email")}
                  required
                  disabled
                  value={user?.email ?? ""}
                />
              </Grid2>
              <Grid2 xs={12} md={6}>
                <InputReactHookForm
                  label={t("Phone")}
                  placeholder={t("Phone")}
                  name="phoneNumber"
                  control={control}
                  rules={{
                    pattern: {
                      value: /^[0-9]{10}$/,
                      message: t("must-be-a-valid", {
                        param: t("Phone"),
                      }),
                    },
                  }}
                  defaultValue=""
                />
              </Grid2>
            </Grid2>
          </Box>

          <Button variant="contained" color="secondary" type="submit">
            {t("Save")}
          </Button>
        </Box>
      </form>
    </Paper>
  );
};

export default Profile;
