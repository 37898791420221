import apiClient from "../api/axios";
import alertService from "../components/alert/alertService";
import { t } from "i18next";
import { Auxiliary } from "../models/Activities";
import { DROPDOWN_EMISSION_GROUP } from "../constants/dropdown";

export interface GetTemplateResponse {
  fileName: string;
  templateInBase64: string;
}

export interface ValidateFileResponse {
  importActivitiesRef: string;
  numImportActivitiesHistory: number;
  importActivitiesHistory: ImportActivitiesHistory[];
  dataErrors?: DataError[];
}

export interface ImportActivitiesHistory {
  id: number;
  emissionGroup: string;
  asset: number;
  assetRef: string;
  assetName: string;
  assetType: string;
  amount: number;
  startDate: string;
  endDate: string;
  auxiliary: Auxiliary;
  vender: string | null;
  remark: string | null;
  suborganization: number;
  suborganizationRef: string;
  suborganizationName: string;
  emissionFactorRef: string;
  activityTypeEn: string;
  activityAttributeEn: string;
  activityTypeTh: string;
  activityAttributeTh: string;
  unit: string;
}

export interface ImportActivitiesHistoryForDataTableResponse {
  numResult: number;
  importActivitiesHistory: ImportActivitiesHistoryForDataTable[];
}

export interface DataError {
  row: number;
  errorDetails: ErrorDetail[];
}

export interface ErrorDetail {
  type: string;
  column: string;
}

export interface ImportActivitiesHistoryForDataTable {
  id: number;
  _id: string;
  filename: string;
  excelBase64: string;
  recordCount: number;
  status: string;
  importedBy: ImportedBy;
  errorCount: number;
  errorDetails: any[];
  activitiesRef: ActivitiesRef[];
  createdAt: string;
}

export interface ImportedBy {
  email: string;
  displayName: string;
}

export interface ActivitiesRef {
  id: number;
  assetRef: AssetRef;
  amount: number;
  startDate: string;
  endDate: string;
  suborganizationRef: SuborganizationRef;
  status: string;
  emissionFactorRef: EmissionFactorRef;
  displayName: any;
}

export interface AssetRef {
  name: string;
  assetType: string;
}

export interface SuborganizationRef {
  name: string;
}

export interface EmissionFactorRef {
  _id: string;
  emissionGroup: string;
  typeEn: string;
  typeTh: string;
  groupTh: string;
  attributeTh: string;
  unit: string;
  publisherRef: PublisherRef;
  attributeEn: any;
  groupEN: any;
}

export interface PublisherRef {
  publisher: string;
  publishReference: string;
}

export interface FileDetail {
  refId: string;
  filename: string;
  excelBase64String: string;
}

const ImportFileService = {
  getTemplate: async (): Promise<GetTemplateResponse | null> => {
    try {
      alertService.showLoading();

      const response = await apiClient.get(`/v1/activities/import/template`);

      alertService.closeLoading();

      return response.data.results;
    } catch (error) {
      handleError(error);
    }

    return null;
  },
  validateFile: async (
    filename: string,
    excelBase64String: string,
    importHistoryRefId?: string
  ): Promise<ValidateFileResponse | null> => {
    const body: any = {
      filename: filename,
      excelBase64String: excelBase64String.split("base64,")[1],
    };

    if (importHistoryRefId) {
      body.importHistoryRefId = importHistoryRefId;
    }

    try {
      alertService.showLoading();

      const response = await apiClient.post(
        `/v1/activities/import/validate`,
        body
      );

      alertService.closeLoading();

      return response.data.results;
    } catch (error: any) {
      console.log(error);
      handleError(error);
    }

    return null;
  },
  importFile: async (
    organizationId: number,
    organizationRef: string,
    validateFileResponse: ValidateFileResponse
  ): Promise<ImportActivitiesHistoryForDataTableResponse | null> => {
    const activities = validateFileResponse?.importActivitiesHistory.map(
      (e) => ({
        id: e.id,
        asset: e.asset,
        assetRef: e.assetRef,
        amount: e.amount,
        startDate: e.startDate,
        endDate: e.endDate,
        organization: organizationId,
        organizationRef: organizationRef,
        suborganization: e.suborganization,
        suborganizationRef: e.suborganizationRef,
        emissionFactorRef: e.emissionFactorRef,
        vender: e.vender,
        remark: e.remark,
        auxiliary: e.auxiliary,
        emissionGroup:
          DROPDOWN_EMISSION_GROUP.find(
            (i) => i.emissionGroup === e.emissionGroup
          )?._id ?? "",
      })
    );

    const body = {
      importActivitiesRef: validateFileResponse.importActivitiesRef,
      activities: activities,
    };

    try {
      alertService.showLoading();

      const response = await apiClient.post(`/v1/activities/import`, body);

      alertService.closeLoading();

      return response.data.results;
    } catch (error) {
      handleError(error);
    }

    return null;
  },
  getImportHistories:
    async (): Promise<ImportActivitiesHistoryForDataTableResponse | null> => {
      try {
        alertService.showLoading();
        const response = await apiClient.get(`/v1/activities/import`);

        alertService.closeLoading();

        return response.data.results;
      } catch (error) {
        handleError(error);
      }

      return null;
    },
  deleteImportHistory: async (
    refId: string
  ): Promise<ImportActivitiesHistoryForDataTableResponse | null> => {
    try {
      alertService.showLoading();
      const response = await apiClient.patch(`/v1/activities/import/${refId}`);

      alertService.closeLoading();

      return response.data.results;
    } catch (error) {
      handleError(error);
    }

    return null;
  },
  getImportHistoryFile: async (refId: string): Promise<FileDetail | null> => {
    try {
      alertService.showLoading();

      const response = await apiClient.get(`/v1/activities/import/${refId}`);

      alertService.closeLoading();

      return response.data.results;
    } catch (error) {
      handleError(error);
    }

    return null;
  },
};

const handleError = async (error: any) => {
  if (error.response && error.response.data && error.response.data.code) {
    const errorMessage = error.response.data.code;
    switch (errorMessage) {
      case "EXCEL_PARSING_FROM_BASE64_FAILED":
        await alertService.error(
          t("upload-incomplete-detail"),
          t("upload-incomplete")
        );
        break;
      case "FORBIDDEN_ACCESS_ERROR":
        await alertService.error(t("Forbidden-Accrss-Error"));
        break;
      case "VALIDATION_ERROR":
        await alertService.error(t("validation-failed"));
        break;
      case "EXCEL_WORKSHEET_EMPTY":
        await alertService.error(t("Excel-worksheet-incompleted"));
        break;
      case "RESOURCE_NOT_FOUND":
        await alertService.error(t("noResultsOverlayLabel"));
        break;
      default:
        alertService.error(
          t("upload-incomplete-detail"),
          t("upload-incomplete")
        );
        break;
    }
  } else {
    alertService.error(t("cannot-connect-to-server"));
  }
};

export default ImportFileService;
