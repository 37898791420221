import { Box, Typography } from "@mui/material";
import InfoPopper from "../popper/InfoPopper";
import {
  DateView,
  LocalizationProvider,
  MobileDatePicker,
} from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CSSProperties } from "react";
import "dayjs/locale/th";

export const iconStyle: CSSProperties = {
  position: "absolute",
  top: "50%",
  right: "12px",
  transform: "translate(0, -50%)",
  pointerEvents: "none",
};

interface Props {
  label: string;
  info: string;
  disabled?: boolean;
  required?: boolean;
  defaultDate: Dayjs | null;
  value: Dayjs | null;
  inputRef: any;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  initDate?: Dayjs | null;
  handleOnChange?: (...event: any[]) => void;
  format?: string;
  views?: DateView[];
}

const CustomDatePicker = (props: Props) => {
  const { t, i18n } = useTranslation(["common"], { nsMode: "fallback" });

  return (
    <LocalizationProvider
      adapterLocale={i18n.language.split("-")[0] ?? i18n.language}
      dateAdapter={AdapterDayjs}
    >
      <Typography
        variant="h3"
        color={"var(--dark)"}
        sx={{ marginBottom: "8px" }}
      >
        {props.label}
        {props.required === true ? (
          <span style={{ color: "var(--red)" }}> * </span>
        ) : (
          <span> </span>
        )}
        <InfoPopper text={props.info} />
      </Typography>

      <Box width={"100%"} display={"block"} position={"relative"}>
        <MobileDatePicker
          value={props.value}
          defaultValue={props.defaultDate}
          onChange={props.handleOnChange}
          format={props.format ?? "MM/YYYY"}
          // minDate={
          //   props.initDate
          //     ? dayjs(`${props.initDate.year()}-01`)
          //     : dayjs(`${dayjs().year() - 2}-01`)
          // }
          // maxDate={
          //   props.initDate
          //     ? dayjs(`${props.initDate.year()}-12`)
          //     : dayjs(`${dayjs().year()}-12`)
          // }
          minDate={dayjs(`${dayjs().year() - 1}-01`)}
          maxDate={dayjs(`${dayjs().year()}-12`)}
          views={props.views ?? ["year", "month"]}
          inputRef={props.inputRef}
          monthsPerRow={3}
          yearsPerRow={3}
          slotProps={{
            textField: {
              error: props.error === undefined ? false : true,
              size: "small",
              inputProps: {
                style: {
                  padding: "0px 0px !important",
                  width: "100%",
                },
              },
            },
          }}
          sx={{
            width: "100%",
            height: "40px",
          }}
          localeText={{
            toolbarTitle: props.label,
            okButtonLabel: t("Ok"),
            cancelButtonLabel: t("Cancel"),
          }}
          disableFuture
        />
        <img style={iconStyle} src="/img/calendar.svg" alt="calendar" />
      </Box>
      {props.error && (
        <Typography
          marginTop={1}
          variant="text2"
          style={{ color: "var(--red)" }}
        >
          {props.error.message?.toString()}
        </Typography>
      )}
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
