import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import InfoPopper from "../popper/InfoPopper";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  label: string;
  info?: string;
  value?: string;
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
  endIcon?: JSX.Element;
  placeholder?: string;
  errorMessage?: string;
  handleOnChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  displayOnly?: boolean;
}

const CustomInput = (props: Props) => {
  const { t } = useTranslation(["common", "input"], {
    nsMode: "fallback",
  });

  return (
    <Box>
      <Typography variant="h3" color={"var(--dark)"}>
        {props.label}

        {props.required === true && (
          <span style={{ color: "var(--red)" }}> * </span>
        )}

        {(props.required === false || props.required === undefined) &&
          (props.displayOnly === false || props.displayOnly === undefined) && (
            <span> ({t("Optional")}) </span>
          )}

        {props.info && <InfoPopper text={props.info} />}
      </Typography>
      <TextField
        fullWidth
        value={props.value}
        error={props.error}
        disabled={props.disabled}
        placeholder={props.placeholder}
        onChange={props.handleOnChange}
        style={{
          margin: "8px 0",
          height: "40px",
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{props.endIcon}</InputAdornment>
          ),
        }}
      />
      {props.error && (
        <Typography variant="text2" style={{ color: "var(--red)" }}>
          {props.errorMessage}
        </Typography>
      )}
    </Box>
  );
};

export default CustomInput;
