import React, { useEffect, useState } from "react";
import Layout from "../../Layout";
import {
  Box,
  Autocomplete,
  Checkbox,
  TextField,
  AutocompleteChangeReason,
  Button,
  Typography,
  Grid,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DashbroadContext } from "../../contexts/DashbroadContext";
import { useTranslation } from "react-i18next";
import Emission, { EmissionData } from "../../models/Emission";
import dayjs from "dayjs";
import { Link, Outlet, useLocation } from "react-router-dom";
import apiClient from "../../api/axios";
import { SubOrganizations } from "../../models/SubOrganization";
import alertService from "../alert/alertService";
import { generateDateList } from "../../utils/dateUtils";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { iconStyle } from "../input/CustomDatePicker";

const DashboardLayout = () => {
  const [emissions, setEmissions] = useState<EmissionData[]>([]);
  const [subOrganizations, setSubOrganizations] = useState<SubOrganizations[]>(
    []
  );
  const [selectSubOrganizations, setSelectSubOrganizations] = useState<
    string[]
  >([]);
  const [startDate, setStartDate] = useState<dayjs.Dayjs>(() => {
    const currentDate = dayjs();
    return currentDate.startOf("year");
  });
  const [endDate, setEndDate] = useState<dayjs.Dayjs>(() => {
    const currentDate = dayjs();
    return currentDate.endOf("year");
  });

  const { t } = useTranslation(["common", "overview"], { nsMode: "fallback" });
  const { pathname } = useLocation();

  const filteredSubOrganizationEmssions =
    process.env.REACT_APP_IS_OPEN_SCOPE_3 === "true"
      ? emissions.filter(
          (item) =>
            item.suborganizationName !== undefined &&
            selectSubOrganizations.includes(item.suborganizationName)
        )
      : emissions.filter(
          (item) =>
            item.suborganizationName !== undefined &&
            selectSubOrganizations.includes(item.suborganizationName) &&
            item.scope !== "Scope-3"
        );

  const filteredEmissions = filteredSubOrganizationEmssions.filter(
    (item) =>
      item.date &&
      startDate.valueOf() <= item.date &&
      item.date <= endDate.valueOf()
  );

  const context = {
    emissions: filteredSubOrganizationEmssions,
    filteredEmissions: filteredEmissions,
    startDate: startDate,
    endDate: endDate,
  };

  const organizationOptions = subOrganizations.map((item) => item.name);

  const onOrganizationChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string[],
    reason: AutocompleteChangeReason
  ) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Backspace" ||
        (event as React.KeyboardEvent).key === "Delete") &&
      reason === "removeOption"
    ) {
      return;
    }

    setSelectSubOrganizations(newValue);
  };

  const pickStartDate = (day: dayjs.Dayjs) => {
    setStartDate(day.startOf("year"));
    setEndDate(day.endOf("year"));
  };

  const pickEndDate = (day: dayjs.Dayjs) => {
    const endYear = day.endOf("year");
    setEndDate(endYear);
    if (startDate.diff(endYear) > 0) {
      setStartDate(day.startOf("year"));
    }
  };

  const onDefaultClick = () => {
    const currentDate = dayjs();
    setSelectSubOrganizations(subOrganizations.map((item) => item.name));
    setStartDate(currentDate.startOf("year"));
    setEndDate(currentDate.endOf("year"));
  };

  const getEmission = async () => {
    try {
      const response = await apiClient.get("/v1/emissions");
      if (response.status === 200) {
        const emissions: Emission[] = response.data.results.emissions;
        const spanEmissions: EmissionData[] = emissions.flatMap((item) => {
          return Object.entries(item.reportGroups).flatMap(([key, value]) => {
            const [scopeKey, ...types] = key.split("-");
            const type = types.join("-").replaceAll(/[()]/g, "");
            let scope: "Scope-1" | "Scope-2" | "Scope-3" | "Outside-of-Scope";
            if (scopeKey === "s1") {
              scope = "Scope-1";
            } else if (scopeKey === "s2") {
              scope = "Scope-2";
            } else if (scopeKey === "s3") {
              scope = "Scope-3";
            } else {
              scope = "Outside-of-Scope";
            }
            if (item.startDate !== undefined && item.endDate !== undefined) {
              const dateRange = generateDateList(item.startDate, item.endDate);
              const splitValue = value / dateRange.length;
              return dateRange.map((date) => {
                return {
                  ...item,
                  scope: scope,
                  value: splitValue / 1000,
                  type: type,
                  date: date,
                };
              });
            }
            return [];
          });
        });
        setEmissions(spanEmissions);
      }
    } catch (error: any) {}
  };

  const getSubOrganizations = async () => {
    try {
      const response = await apiClient.get("/v1/suborganizations");
      if (response.status === 200) {
        setSubOrganizations(response.data.results.suborganizations);
      }
    } catch (error: any) {
      alertService.error(t("error-get-sub-organization"), t("Error-Message"));
    }
  };

  useEffect(() => {
    getEmission();
    getSubOrganizations();
  }, []);

  useEffect(() => {
    setSelectSubOrganizations(subOrganizations.map((item) => item.name));
  }, [subOrganizations]);

  return (
    <Layout>
      <DashbroadContext.Provider value={context}>
        {/* Filter */}
        <Box
          p={"12px 40px"}
          display={"flex"}
          flexDirection={{
            xs: "column",
            xl: "row",
          }}
          bgcolor={"#FAFAFA"}
          gap={"22px"}
          alignItems={"start"}
        >
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={{ xs: "column", md: "row" }}
            gap={"22px"}
          >
            <Grid flex={1}>
              <Autocomplete
                size="small"
                multiple
                limitTags={2}
                options={organizationOptions}
                value={selectSubOrganizations}
                disableCloseOnSelect
                renderOption={(props, option, { selected }) => {
                  return (
                    <li {...props}>
                      <Checkbox style={{ marginRight: 8 }} checked={selected} />
                      {option}
                    </li>
                  );
                }}
                sx={{
                  width: "100%",
                  backgroundColor: "var(--white)",
                  // "& .MuiAutocomplete-inputRoot": {
                  //   maxHeight: "37.13px",
                  // },
                  "& .MuiAutocomplete-tag": {
                    maxWidth: "40%",
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Organization")}
                    sx={{ zIndex: 0 }}
                  />
                )}
                onChange={onOrganizationChange}
              />
            </Grid>
            <Grid
              xs={12}
              sm={"auto"}
              color={"var(--dark)"}
              display={"flex"}
              alignItems={"center"}
              gap={"8px"}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box display={"block"} position={"relative"}>
                  <MobileDatePicker
                    label={t("Start-Year")}
                    views={["year"]}
                    sx={{
                      backgroundColor: "var(--white)",
                      height: "37px",
                    }}
                    value={dayjs(startDate)}
                    onChange={(value) => value && pickStartDate(value)}
                    localeText={{
                      toolbarTitle: t("Start-Year"),
                      okButtonLabel: t("Ok"),
                      cancelButtonLabel: t("Cancel"),
                    }}
                  />

                  <img
                    style={iconStyle}
                    src="/img/calendar.svg"
                    alt="calendar"
                  />
                </Box>

                <Typography>{t("To")}</Typography>

                <Box display={"block"} position={"relative"}>
                  <MobileDatePicker
                    label={t("End-Year")}
                    views={["year"]}
                    sx={{ backgroundColor: "var(--white)", height: "37px" }}
                    value={dayjs(endDate)}
                    onChange={(value) => value && pickEndDate(value)}
                    localeText={{
                      toolbarTitle: t("Start-Year"),
                      okButtonLabel: t("Ok"),
                      cancelButtonLabel: t("Cancel"),
                    }}
                  />
                  <img
                    style={iconStyle}
                    src="/img/calendar.svg"
                    alt="calendar"
                  />
                </Box>

                <Button variant="text" color="green" onClick={onDefaultClick}>
                  {t("Default")}
                </Button>
              </LocalizationProvider>
            </Grid>
            {/* <Grid
              xs={12}
              sm={"auto"}
              color={"var(--dark)"}
              display={"flex"}
              alignItems={"center"}
              gap={"8px"}
            >
              <Button variant="text" color="green" onClick={onDefaultClick}>
                {t("Default")}
              </Button>
            </Grid> */}
          </Box>
          {/* <Grid container spacing={"22px"}>
            <Grid xs={12} sm={"auto"}>
              <Autocomplete
                size="small"
                multiple
                limitTags={3}
                options={organizationOptions}
                value={selectSubOrganizations}
                disableCloseOnSelect
                renderOption={(props, option, { selected }) => {
                  return (
                    <li {...props}>
                      <Checkbox style={{ marginRight: 8 }} checked={selected} />
                      {option}
                    </li>
                  );
                }}
                sx={{
                  width: "500px",
                  backgroundColor: "var(--white)",
                  maxWidth: "500px",
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Organization")}
                    sx={{ zIndex: 0 }}
                  />
                )}
                onChange={onOrganizationChange}
              />
            </Grid>
            <Grid
              xs={12}
              sm={"auto"}
              color={"var(--dark)"}
              display={"flex"}
              alignItems={"center"}
              gap={"8px"}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box display={"block"} position={"relative"}>
                  <MobileDatePicker
                    label={t("Start-Year")}
                    views={["year"]}
                    sx={{
                      backgroundColor: "var(--white)",
                      height: "37px",
                    }}
                    value={dayjs(startDate)}
                    onChange={(value) => value && pickStartDate(value)}
                    localeText={{
                      toolbarTitle: t("Start-Year"),
                      okButtonLabel: t("Ok"),
                      cancelButtonLabel: t("Cancel"),
                    }}
                  />

                  <img
                    style={iconStyle}
                    src="/img/calendar.svg"
                    alt="calendar"
                  />
                </Box>

                <Typography>{t("To")}</Typography>

                <Box display={"block"} position={"relative"}>
                  <MobileDatePicker
                    label={t("End-Year")}
                    views={["year"]}
                    sx={{ backgroundColor: "var(--white)", height: "37px" }}
                    value={dayjs(endDate)}
                    onChange={(value) => value && pickEndDate(value)}
                    localeText={{
                      toolbarTitle: t("Start-Year"),
                      okButtonLabel: t("Ok"),
                      cancelButtonLabel: t("Cancel"),
                    }}
                  />
                  <img
                    style={iconStyle}
                    src="/img/calendar.svg"
                    alt="calendar"
                  />
                </Box>
              </LocalizationProvider>
            </Grid>
            <Grid
              xs={12}
              sm={"auto"}
              color={"var(--dark)"}
              display={"flex"}
              alignItems={"center"}
              gap={"8px"}
            >
              <Button variant="text" color="green" onClick={onDefaultClick}>
                {t("Default")}
              </Button>
            </Grid>
          </Grid> */}
        </Box>

        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={"12px"}
          m={"12px 40px"}
        >
          {/* Menu */}
          <Grid
            container
            display={"flex"}
            overflow={"hidden"}
            borderRadius={"50px"}
          >
            <Grid flexGrow="1">
              <Link to={"/overview/total-emissions"}>
                <Button
                  variant="contained"
                  color={
                    pathname === "/overview/total-emissions" ? "green" : "white"
                  }
                  disableElevation
                  sx={{
                    width: "100%",
                    borderRadius: 0,
                  }}
                >
                  {t("Total-emissions")}
                </Button>
              </Link>
            </Grid>
            <Grid flexGrow="1">
              <Link to={"/overview/scope-1"}>
                <Button
                  variant="contained"
                  color={pathname === "/overview/scope-1" ? "green" : "white"}
                  disableElevation
                  sx={{
                    width: "100%",
                    borderRadius: 0,
                  }}
                >
                  {t("Scope-1-emissions")}
                </Button>
              </Link>
            </Grid>
            <Grid flexGrow="1">
              <Link to={"/overview/scope-2"}>
                <Button
                  variant="contained"
                  color={pathname === "/overview/scope-2" ? "green" : "white"}
                  disableElevation
                  sx={{
                    width: "100%",
                    borderRadius: 0,
                  }}
                >
                  {t("Scope-2-emissions")}
                </Button>
              </Link>
            </Grid>
            <Grid flexGrow="1">
              <Link
                to={"/overview/scope-3"}
                style={{
                  pointerEvents:
                    process.env.REACT_APP_IS_OPEN_SCOPE_3 === "true"
                      ? "auto"
                      : "none",
                }}
              >
                <Button
                  disabled={process.env.REACT_APP_IS_OPEN_SCOPE_3 !== "true"}
                  variant="contained"
                  color={pathname === "/overview/scope-3" ? "green" : "white"}
                  disableElevation
                  sx={{
                    width: "100%",
                    borderRadius: 0,
                  }}
                >
                  {t("Scope-3-emissions")}
                </Button>
              </Link>
            </Grid>
            <Grid flexGrow="1">
              {/* <Link to={"/overview/other"}> */}
              <Button
                disabled
                variant="contained"
                color={pathname === "/overview/other" ? "green" : "white"}
                disableElevation
                sx={{
                  width: "100%",
                  borderRadius: 0,
                }}
              >
                {t("Others")}
              </Button>
              {/* </Link> */}
            </Grid>
          </Grid>
          <Outlet />
        </Box>
      </DashbroadContext.Provider>
    </Layout>
  );
};

export default DashboardLayout;
