import { t } from "i18next";
import { FieldValues, RegisterOptions, UseFormWatch } from "react-hook-form";

export const MAX_UPLOAD_FILE_SIZE: number = 5e6;

export const MAX_UPLOAD_FILE_SIZE_IMPORT: number = 5e6;

export interface InputData {
  label: string;
  info: string;
}

export interface InputAuxiliary {
  emissionGroup: string;
  amount: InputData;
  auxiliary?: InputData[];
}

export const INPUT_AUXILIARY: InputAuxiliary[] = [
  {
    emissionGroup: "Fuel",
    amount: {
      label: "amount-of-fuel",
      info: "amount-of-fuel",
    },
  },
  {
    emissionGroup: "Energy",
    amount: {
      label: "Amount-of-Energy",
      info: "Amount-of-Energy",
    },
  },
  {
    emissionGroup: "Wastewater",
    amount: {
      label: "amount-of-organic-loading",
      info: "amount-of-organic-loading",
    },
    auxiliary: [
      // {
      //   label: "Wastewater-Industry-Type",
      //   info: "Wastewater-Industry-Type",
      // },
      {
        label: "",
        info: "",
      },
      {
        label: "BOD-COD-Value",
        info: "BOD-Value-Description",
      },
      {
        label: "Amount-of-Wastewater",
        info: "Amount-of-Wastewater",
      },
      {
        label: "Amount-of-Sludge",
        info: "Amount-of-Sludge-Description",
      },
      // {
      //   label: "Amount-of-Methane-Recovery",
      //   info: "Amount-of-Methane-Recovery",
      // },
    ],
  },
  {
    emissionGroup: "Refrigerants",
    amount: {
      label: "Amount-of-Refrigerants",
      info: "Amount-of-Refrigerants-Description",
    },
  },
  {
    emissionGroup: "Outsourcing Waste-disposal",
    amount: {
      label: "Waste",
      info: "Waste",
    },
    auxiliary: [
      {
        label: "Waste-Type",
        info: "Waste-Type",
      },
    ],
  },
  {
    emissionGroup: "Septic Tank",
    amount: {
      label: "amount-of-population-load",
      info: "amount-of-population-load",
    },
    auxiliary: [
      // {
      //   label: "Septic-Tank-Type",
      //   info: "Septic-Tank-Type",
      // },
      {
        label: "",
        info: "",
      },
      {
        label: "Person-On-site",
        info: "Amount-of-Persons-Description",
      },
      {
        label: "On-site-Schedule",
        info: "On-site-Schedule",
      },
      {
        label: "",
        info: "",
      },
      // {
      //   label: "Leave-day",
      //   info: "Leave-day",
      // },
    ],
  },
  {
    emissionGroup: "Fertilizer",
    amount: {
      label: "Amount-of-Key-Substance",
      info: "Amount-of-Key-Substance",
    },
    // auxiliary: [
    //   // {
    //   //   label: "Fertilizer-Type",
    //   //   info: "Fertilizer-Type-Description",
    //   // },
    //   {
    //     label: "",
    //     info: "",
    //   },
    //   {
    //     label: "Amount-of-Fertilizer",
    //     info: "Amount-of-Fertilizer",
    //   },
    //   {
    //     label: "Fertilizer-Nitrogen",
    //     info: "Fertilizer-Nitrogen",
    //   },
    //   {
    //     label: "Fertilizer-Phosphorus",
    //     info: "Fertilizer-Phosphorus",
    //   },
    //   {
    //     label: "Fertilizer-Potassium",
    //     info: "Fertilizer-Potassium",
    //   },
    // ],
  },
  {
    emissionGroup: "Business Travel",
    amount: {
      label: "input-amount-of-distance",
      info: "Distance-Description",
    },
    auxiliary: [
      {
        label: "trip-type",
        info: "trip-type",
      },
      {
        label: "Distance-(One-way)",
        info: "Distance-(One-way)",
      },
      {
        label: "Amount-Of-Employees",
        info: "Amount-Of-Employees-Description",
      },
    ],
  },
  {
    emissionGroup: "Employee Commuting",
    amount: {
      label: "input-amount-of-distance",
      info: "Distance-Description",
    },
    auxiliary: [
      {
        label: "trip-type",
        info: "trip-type",
      },
      {
        label: "Distance-(One-way)",
        info: "Distance-(One-way)",
      },
      {
        label: "Amount-Of-Employees",
        info: "Amount-Of-Employees-Description",
      },
    ],
  },
  {
    emissionGroup: "Fire Extinguishing",
    amount: {
      label: "Amount-of-Fire-Extinguishing",
      info: "Amount-of-Fire-Extinguishing-Description",
    },
  },
  {
    emissionGroup: "Other Fugitive-emissions",
    amount: {
      label: "Amount-of-OtherFugitiveEmission",
      info: "Amount-of-OtherFugitiveEmission-Description",
    },
  },
  {
    emissionGroup: "Purchases",
    amount: {
      label: "Amount-of-Purchases",
      info: "Amount-of-Purchases-Description",
    },
    // auxiliary: [
    //   {
    //     label: "purchase-type",
    //     info: "purchase-type",
    //   },
    // ],
  },
  {
    emissionGroup: "Water",
    amount: {
      label: "Amount-of-Water",
      info: "Amount-of-Water",
    },
  },
  {
    emissionGroup: "Outsourcing Transport",
    amount: {
      label: "input-amount-of-distance",
      info: "input-amount-of-distance",
    },
    auxiliary: [
      {
        label: "Goods-Type",
        info: "Goods-Type-Description",
      },
      {
        label: "Distance-One-way-km",
        info: "Distance-One-way-km",
      },
      {
        label: "Loading-tonne",
        info: "Loading-tonne",
      },
    ],
  },
  {
    emissionGroup: "Capital Goods",
    amount: {
      label: "Amount",
      info: "Amount",
    },
  },
];

export const USER_STATUS = [
  {
    status: "activated",
    textColor: "var(--green)",
    bgColcor: "var(--status-approved-complete-active-bg)",
  },
  {
    status: "pending",
    textColor: "var(--status-pending-text)",
    bgColcor: "var(--status-pending-bg)",
  },
];

export const USER_ROLES = [
  {
    status: "admin",
    name: "Admin",
  },
  {
    status: "user",
    name: "User",
  },
];

export const NUMBER_REGEX: RegExp = /^\d+(\.\d+)?$/;

export const PASSWORD_RUELS: Omit<
  RegisterOptions<FieldValues, string>,
  "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
> = {
  required: t("Password-must-be-at-least-6-characters"),
  pattern: {
    value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W_]).{6,20}$/,
    message: t("password-fails-to-match-pattern"),
  },
};
