import { Box } from "@mui/material";
import { DataGrid, GridColDef, useGridApiRef } from "@mui/x-data-grid";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { tableStyle } from "../emission/data-table/EmissionDataTableStyle";
import { CustomPagination } from "../../components/datagrid/CustomPagination";
import { QuickSearchToolbar } from "./QuickSearchToolbar";

export const ReportDataTable = () => {
  const { t, i18n } = useTranslation(["common", "input", "report"], {
    nsMode: "fallback",
  });

  const apiRef = useGridApiRef();

  const [rows, setRows] = useState<any[]>([{ id: 1, exported: "01/01/2022" }]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: t("ID"),
      disableColumnMenu: true,
      minWidth: 80,
      type: "string",
      headerAlign: "left",
      align: "left",
      valueFormatter: (value) => `IM-${value}`,
    },
    {
      field: "exported",
      headerName: t("Exported"),
      disableColumnMenu: true,
      type: "string",
      headerAlign: "left",
      align: "left",
      minWidth: 160,
    },
    {
      field: "reporting-period",
      headerName: t("Reporting-Period"),
      disableColumnMenu: true,
      type: "string",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "reporting-name",
      headerName: t("Reporting-Name"),
      disableColumnMenu: true,
      type: "string",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
  ];

  return (
    <Box width={"100%"}>
      <DataGrid
        rows={rows}
        columns={columns}
        rowHeight={70}
        disableRowSelectionOnClick
        apiRef={apiRef}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        disableColumnMenu
        slots={{
          toolbar: QuickSearchToolbar,
          pagination: CustomPagination,
        }}
        localeText={{
          toolbarExport: t("Export"),
          toolbarQuickFilterPlaceholder: `${t("search")}...`,
          noRowsLabel: t("noRowsLabel"),
          noResultsOverlayLabel: t("noResultsOverlayLabel"),
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: {
              variant: "outlined",
            },
          },
        }}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
          filter: {
            filterModel: {
              items: [],
            },
          },
        }}
        pageSizeOptions={[10, 20]}
        sx={tableStyle()}
      />
    </Box>
  );
};
