import { Box, Button, Card } from "@mui/material";
import Layout from "../../Layout";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { OrganizationProvider } from "../../contexts/OrganizationContext";
import SubOrganizations from "./SubOrganizations";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TabMenu, TabMenuData } from "../../components/tabMenu/TabMenu";

const BusinessStructure = () => {
  // const { t } = useTranslation(["common", "organization"], {
  //   nsMode: "fallback",
  // });
  // const { pathname } = useLocation();

  const tabMenuData: TabMenuData[] = [
    { pathname: "/setting/business-structure/profile", name: "Profile" },
    {
      pathname: "/setting/business-structure/asset",
      name: "Asset",
    },
  ];

  return (
    <Layout>
      <OrganizationProvider>
        <Box
          height={{ lg: "100%" }}
          boxSizing={"border-box"}
          pt={"8px"}
          bgcolor={"var(--white)"}
        >
          <Grid2 container p={"40px"} spacing={"40px"} height={"100%"}>
            <Grid2
              xs={12}
              lg={4}
              maxWidth={{ lg: "338px" }}
              height={{
                lg: "100%",
              }}
              overflow={"auto"}
            >
              <SubOrganizations />
            </Grid2>
            <Grid2
              xs
              height={{
                lg: "100%",
              }}
            >
              <Box bgcolor={"var(--background)"}>
                <TabMenu tabMenuData={tabMenuData} />
              </Box>

              <Card
                elevation={0}
                variant="outlined"
                sx={{
                  p: "20px",
                  height: { lg: "95%" },
                  boxSizing: "border-box",
                  display: "flex",
                  flexDirection: "column",
                  gap: "40px",
                  overflow: "auto",
                }}
              >
                {/* <Box display={"flex"} flexDirection={"row"} gap={"12px"}>
                  <Link to={"/setting/business-structure/profile"}>
                    <Button
                      variant={
                        pathname.includes("profile") ? "contained" : "outlined"
                      }
                      sx={{ md: { width: "160px" } }}
                    >
                      {t("Profile")}
                    </Button>
                  </Link>
                  <Link to={"/setting/business-structure/asset"}>
                    <Button
                      variant={
                        pathname.includes("asset") ? "contained" : "outlined"
                      }
                      sx={{ md: { width: "160px" } }}
                    >
                      {t("Asset")}
                    </Button>
                  </Link>
                </Box> */}
                <Box
                  display={"flex"}
                  flex={1}
                  sx={{
                    overflow: "auto",
                  }}
                >
                  <Outlet />
                </Box>
              </Card>
            </Grid2>
          </Grid2>
        </Box>
      </OrganizationProvider>
    </Layout>
  );
};

export default BusinessStructure;
