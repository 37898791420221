import { Box, Checkbox, Typography } from "@mui/material";
import { ChangeEvent } from "react";

interface Props {
    text: string;
    color: string;
    checked?: boolean;
    onChange?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

const ChartCheckbox = (props: Props) => {
    return (
        <Box display={"inline-flex"} gap={"8px"} alignItems={"center"}>
            <Checkbox checked={props.checked} sx={{ p: 0 }} onChange={props.onChange} />
            <Box width={"16px"} height={"16px"} bgcolor={[props.color]} />
            <Typography variant="body1">{props.text}</Typography>
        </Box>
    );
};

export default ChartCheckbox;
