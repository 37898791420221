import { Box, Breadcrumbs, Typography } from "@mui/material";
import { t } from "i18next";

interface Props {
  organization: string;
  location: string;
  subOrganizations: string;
}

export const OrganizationBreadcrumbs = (props: Props) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyItems: "center",
          marginTop: "20px",
        }}
      >
        <img src="/img/buildings-black.svg" alt="icon" />

        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{
            alignSelf: "center",
            padding: "0px 20px 0px 12px",
          }}
        >
          <Typography color="var(--gray4)" variant="h3">
            {t("Organization")} : {props.organization}
          </Typography>
          {props.location !== "" ? (
            <Typography color="var(--dark)" variant="h3">
              {t("Location")} : {props.location}
            </Typography>
          ) : null}

          {props.subOrganizations !== "" ? (
            <Typography color="var(--dark)" variant="h3">
              {props.subOrganizations}
            </Typography>
          ) : null}
        </Breadcrumbs>

        {/* <TableApprovalStatus status="ownedAndLeasedOut" by="" /> */}
      </Box>
    </>
  );
};
