import { Box } from "@mui/material";
import Layout from "../../../Layout";
import { ActivityTemplatesProvider } from "../../../contexts/ActivityTemplatesContext";
import { ActivityTemplatesTable } from "./ActivityTemplatesTable";
import { AddEmissionProvider } from "../../../contexts/AddEmissionContext";

const ActivityTemplates = () => {
  return (
    <>
      <Layout>
        <Box display={"flex"} flexDirection={"column"} height={"100%"}>
          <Box
            flex={1}
            p={"40px 40px"}
            gap={"40px"}
            display={"flex"}
            flexDirection="column"
            alignItems={"start"}
            bgcolor={"var(--white)"}
          >
            <Box width={"100%"} height={600}>
              <AddEmissionProvider>
                <ActivityTemplatesProvider>
                  <ActivityTemplatesTable />
                </ActivityTemplatesProvider>
              </AddEmissionProvider>
            </Box>
          </Box>
        </Box>
      </Layout>
    </>
  );
};

export default ActivityTemplates;
