import { t } from "i18next";
import apiClient from "../api/axios";
import alertService from "../components/alert/alertService";
import { SubOrganizations } from "../models/SubOrganization";

const SubOrganizationsService = {
  editSubOrganizations: async (
    formData: any
  ): Promise<SubOrganizations[] | null> => {
    try {
      const response = await apiClient.post("/v1/suborganizations", formData);

      if (response.status === 200) {
        const result: SubOrganizations[] =
          response.data.results.suborganizations;
        return result;
      }
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.code) {
        const errorMessage = error.response.data.code;

        switch (errorMessage) {
          case "FORBIDDEN_ACCESS_ERROR":
            alertService.error(t("Forbidden-Accrss-Error"));
            break;
          default:
            alertService.error(t("Cannot-Access-Organization-Error"));
            break;
        }
      } else {
        alertService.error(t("cannot-connect-to-server"));
      }

      alertService.error(t("Cannot-Access-Organization-Error"));
    }
    return null;
  },
};

export default SubOrganizationsService;
