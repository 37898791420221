import {
    Box,
    Card,
    CardContent,
    Typography,
    TableCell,
    TableRow,
    TableContainer,
    Table,
    TableBody,
} from "@mui/material";
import { ChartsAxisContentProps, BarSeriesType } from "@mui/x-charts";
import { TFunction } from "i18next";
import Series from "../../models/Series";
import NumberFormatterUtils from "../../utils/numberFormatterUtils";

const BarChartTooltip = (props: ChartsAxisContentProps, t: TFunction<[string, string], undefined>) => {
    const index = props.dataIndex ?? 0;
    const series: Record<string, Series[]> = {};
    let totalValue = 0;
    props.series.forEach((item) => {
        const barItem = item as BarSeriesType;
        const data = item.data[index] ?? 0;
        item.data.forEach((data) => {
            if (typeof data == "number") {
                totalValue += data;
            }
        });
        const stack = barItem.stack ?? "";
        const old = series[stack];
        if (old === undefined) {
            series[stack] = [];
        }
        if (typeof data == "number") {
            series[stack].push({ data, label: barItem.label, stack: barItem.stack, color: barItem.color });
        }

        return [];
    });
    const seriesValue = Object.values(series);
    if (seriesValue.length === 0) return <Box></Box>;
    if (props.series.length === 1) {
        const item = seriesValue[0][0];
        return (
            <Card sx={{ bgcolor: "var(--white)", borderRadius: "8px" }}>
                <CardContent sx={{ display: "flex", gap: "12px" }}>
                    <Box width={"24px"} height={"24px"} bgcolor={item.color} />
                    <Box>
                        <Typography variant="h3" width={"100%"}>
                            {`${t(props.axisValue)}`}
                        </Typography>
                        <Box display={"flex"} gap={"12px"}>
                            <Typography>{`${NumberFormatterUtils.numberFormat((item.data / totalValue) * 100)}%`}</Typography>
                            <Typography>{`${NumberFormatterUtils.numberFormat(item.data)} tCO2e`}</Typography>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        );
    }
    const titleComponent = seriesValue
        .map((item) => item[0])
        .map((item, index) => {
            if (item !== undefined) {
                let percent;
                let stack = "";
                if (seriesValue.length === 1) {
                    const sumValue = seriesValue[0]
                        .map((item) => item.data)
                        .reduce((previousValue, currentValue) => previousValue + currentValue);
                    percent = (sumValue / totalValue) * 100;
                } else {
                    stack = item?.stack ?? "";
                }

                return (
                    <TableCell key={`title${index}`} colSpan={2}>
                        <Typography variant="h3">
                            {`${t(props.axisValue)}`} {stack} {percent && `(${NumberFormatterUtils.numberFormat(percent)})%`}
                        </Typography>
                    </TableCell>
                );
            }
            return <TableCell key={`title${index}`} />;
        });
    const total = seriesValue.map((data) => {
        return data.map((item) => item.data).reduce((item1, item2) => item1 + item2);
    });

    const totalComponent = total
        .map((item) => `${NumberFormatterUtils.numberFormat(item)} tCO2e`)
        .map((text, index, array) => (
            <TableCell key={`total${props.axisValue}${index}`} colSpan={array.length - 1 === index ? 1 : 2}>
            {text}
            </TableCell>
        ));
    const seriesTranspose = seriesValue[0].map((_, colIndex) => seriesValue.map((row) => row[colIndex]));
    const details = seriesTranspose.map((item, index) => {
        const data = item
            .flatMap((data, index) => {
                const totalData = total[index];
                if (totalData === 0 || data === undefined) {
                    return ["0%", "0 tC02e"];
                }
                return [`${NumberFormatterUtils.numberFormat((data.data / totalData) * 100)}%`, `${NumberFormatterUtils.numberFormat(data.data)} tCO2e`];
            })
            .map((text, index) => {
                return <TableCell key={`details${index}`}>{text}</TableCell>;
            });
        return (
            <TableRow key={`detailrows${index}`}>
                <TableCell>
                    <Box display={"inline-flex"} gap={"12px"}>
                        <Box width={"24px"} height={"24px"} bgcolor={(item[item.length - 1] ?? item[0]).color} />
                        {item[0].label && t(item[0].label)}
                    </Box>
                </TableCell>
                {data}
            </TableRow>
        );
    });

    let trend;
    if (total.length > 1) {
        const total0 = total[0];
        const total1 = total[1];
        if (total0 !== 0 && total1 !== 0) {
            trend = ((total[1] - total[0]) / total[0]) * 100;
        }
    }
    let trendImg = "/img/trend-down.svg";
    if (trend !== undefined && trend >= 0) {
        trendImg = "/img/trend-up.svg";
    }

    return (
        <Box className="tooltip" bgcolor={"var(--white)"} borderRadius={"8px"}>
            <TableContainer>
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            {titleComponent.length > 1 && <TableCell />}
                            {titleComponent}
                        </TableRow>
                        {totalComponent.length > 1 && (
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h3">{t("Total")}</Typography>
                                </TableCell>
                                {totalComponent}
                                {trend !== undefined && (
                                    <TableCell sx={{ textAlign: "end" }}>
                                        <Box display={"flex"}>
                                            <img className="status" src={trendImg} alt="trend" />
                                            <Typography variant="text2" color="var(--status)">
                                                {`${NumberFormatterUtils.numberFormat(trend)}%`}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                )}
                            </TableRow>
                        )}
                        {details}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default BarChartTooltip;
