import { GridActionsCellItem } from "@mui/x-data-grid";
import { TemplateActivities } from "../../../models/TemplateActivities";
import { useEffect, useState } from "react";
import {
  AddEmissionContext,
  EmissionMode,
  FormValuesStepTwo,
} from "../../../contexts/AddEmissionContext";
import CustomModal from "../../../components/modal/CustomModal";
import { Typography, Box, Button } from "@mui/material";
import { t } from "i18next";
import { FormProvider } from "react-hook-form";
import AddEmissionStepTwo from "../../emission/popup-add-emission/step2/AddEmissionStepTwo";
import { DROPDOWN_EMISSION_GROUP } from "../../../constants/dropdown";
import dayjs from "dayjs";
import alertService from "../../../components/alert/alertService";
import { Trans } from "react-i18next";
import TemplateActivitiesService from "../../../service/templateActivitiesService";
import { ActivityTemplatesContext } from "../../../contexts/ActivityTemplatesContext";
import { OrganizationBreadcrumbs } from "../../emission/OrganizationBreadcrumbs";
import { useAuth } from "../../../contexts/UserContext";

interface Props {
  row: TemplateActivities;
}

export const EditActivityTemplates = (props: Props) => {
  const [open, setOpen] = useState<boolean>(false);

  const { organization } = useAuth();

  const {
    stepOneForm,
    stepTwoForm,
    subOrganizationList,
    assetList,
    setEmissionMode,
    prepareFormDataToCreateOrDraftTemplates,
  } = AddEmissionContext();

  const { transformersToDataGrid } = ActivityTemplatesContext();

  const onSubmit = async (data: FormValuesStepTwo) => {
    const isConfirmed = await alertService.confirm(
      t("Edit"),
      <Trans
        t={t}
        i18nKey="edit-templates"
        values={{ id: props.row.id }}
        components={{
          span: <span style={{ color: "var(--red)" }} />,
        }}
      />,
      t("Save")
    );

    if (isConfirmed) {
      const formData = prepareFormDataToCreateOrDraftTemplates(data.monthly);

      const result = await TemplateActivitiesService.editTemplateActivities(
        formData,
        props.row._id
      );

      if (result) {
        transformersToDataGrid(result.templateActivities);

        alertService.mixin(t("Successfully"));

        close();
      }
    }
  };

  const close = () => {
    stepTwoForm.reset();
    stepOneForm.reset();
    setOpen(false);
  };

  const setUpForm = () => {
    const item = subOrganizationList.find((i) => {
      return i.name.trim() === props.row.suborganizationName.trim();
    });

    stepOneForm.setValue("subOrganizations", item?.id as string);

    stepOneForm.setValue(
      "location",
      `${item?.location?.address ?? ""} ${item?.location?.district ?? ""} ${
        item?.location?.city ?? ""
      }`.trim()
    );

    const emissionGroupRef: string | undefined = DROPDOWN_EMISSION_GROUP.find(
      (i) => i.emissionGroup === props.row.emissionGroup
    )?.emissionGroup;

    stepOneForm.setValue("emissionGroup", emissionGroupRef as string);

    // form setup
    stepOneForm.setValue(
      "assetName",
      assetList.find((i) => i.name === props.row.assetName)?._id ?? ""
    );

    stepTwoForm.setValue("year", dayjs(dayjs(`${props.row.year}-06-01`)));

    stepTwoForm.setValue("activityGroup", props.row.activityGroupEN ?? "");

    stepTwoForm.setValue("activityType", props.row.activityTypeEn);

    stepTwoForm.setValue("activityAttribute", props.row.activityAttributeEN);

    stepTwoForm.setValue(
      "activityEmissionFactorPublisher",
      props.row.publisherName ?? ""
    );

    stepTwoForm.setValue("amount", props.row.amount.toString() ?? "0");

    stepTwoForm.setValue("unit", props.row.unit ?? "");

    if (props.row.auxiliary) {
      stepTwoForm.setValue("aux1", props.row.auxiliary.aux1 ?? "");

      stepTwoForm.setValue("aux2", props.row.auxiliary.aux2 ?? "");
    }

    stepTwoForm.setValue("monthly", props.row.isMonthly ?? false);
  };

  const { isAdmin } = useAuth();

  useEffect(() => {
    setEmissionMode(EmissionMode.AddActivityTemplates);
    setUpForm();
  }, [open]);

  return (
    <>
      <GridActionsCellItem
        key={"edit-" + props.row._id}
        id={"edit-" + props.row._id}
        icon={<img src="/img/edit-2.svg" alt="icon-edit" />}
        label="edit"
        onClick={() => setOpen(true)}
        disabled={!isAdmin()}
      />
      <CustomModal
        header={
          <Box>
            <Typography variant="h1">
              {t("edit-templates_hl")} {`AT-${props.row.id}`}
            </Typography>
            <OrganizationBreadcrumbs
              organization={organization?.name ?? ""}
              location={stepOneForm.getValues("location") ?? ""}
              subOrganizations={
                subOrganizationList.find(
                  (i) => i.id === stepOneForm.getValues("subOrganizations")
                )?.name ?? ""
              }
            />
          </Box>
        }
        body={
          <Box height={"80dvh"}>
            <FormProvider {...stepTwoForm}>
              <form
                onSubmit={stepTwoForm.handleSubmit(onSubmit)}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <Box flex={1} overflow={"auto"}>
                  <AddEmissionStepTwo />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    marginTop: "44px",
                  }}
                  rowGap={2.5}
                >
                  <Button
                    fullWidth
                    color="secondary"
                    variant="contained"
                    type="submit"
                    name="add"
                  >
                    {t("Save")}
                  </Button>
                </Box>
              </form>
            </FormProvider>
          </Box>
        }
        open={open}
        onClose={close}
      />
    </>
  );
};
