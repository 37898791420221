import React, { useEffect } from "react";
import { NavLink } from "../../models/NavLink";
import { RouteItem } from "../../models/RouteItem";
import { useTranslation } from "react-i18next";
import { Box, Collapse, List } from "@mui/material";
import { useMatches } from "react-router-dom";
import SidebarItem from "./SidebarItem";

interface NavComponentProps {
  routes: RouteItem[];
  link: NavLink;
  parentOpen: boolean;
  index: number;
  width: number;
  leftImgSize?: number;
  leftAvatarSize?: number;
}

const getRoute = (routes: RouteItem[], link: string) => {
  const route = routes.find((item) => item.path === link);
  return {
    name: route?.title,
    link: link,
    image: route?.image,
  };
};

const NavComponent = (props: NavComponentProps) => {
  const { link } = props;
  const matches = useMatches();
  const isCurrentPath =
    matches.find((item) => item.pathname === link.path) !== undefined;

  const [open, setOpen] = React.useState<boolean>(true);

  const { t } = useTranslation(["common"]);

  let chevron = "/img/chevron-right.svg";
  if (open) {
    if (props.parentOpen) {
      chevron = "/img/chevron-down.svg";
    } else {
      // setOpen(false);
    }
  }

  const route = getRoute(props.routes, link.path);
  let image = undefined;
  let children = undefined;
  if (route.image) {
    image = route.image;
  }
  if (link.children) {
    children = link.children.map((item) => {
      return (
        <NavComponent
          key={item.path}
          link={item}
          parentOpen={props.parentOpen}
          index={props.index + 1}
          routes={props.routes}
          width={props.width}
        />
      );
    });
  }
  const paddingLeft = 30 * props.index + "px";

  const handleClick = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <Box>
      <li>
        <SidebarItem
          className={isCurrentPath ? "sidebar-menu seleted" : "sidebar-menu"}
          leftImgType="img"
          leftImg={image}
          leftImgSize={props.leftImgSize}
          leftAvatarSize={props.leftAvatarSize}
          onLeftImgClick={handleClick}
          paddingLeft={paddingLeft}
          showHoverBox={props.index > 0}
          textVariant={props.index === 0 ? "h3" : "body1"}
          text={t(route.name ?? "")}
          link={children ? "" : route.link}
          rightImg={children && chevron}
          rightImgClassName={"chevron"}
          rightImgAlt="More"
          rightImgSize={"10px"}
          onRightImgClick={handleClick}
        />
      </li>
      {children && (
        <Collapse
          className="children"
          in={open && props.parentOpen}
          timeout="auto"
          unmountOnExit
        >
          <List
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "25px",
              p: 0,
              mt: "25px",
            }}
          >
            {children}
          </List>
        </Collapse>
      )}
    </Box>
  );
};

export default NavComponent;
