import { Box, Button, Typography } from "@mui/material";
import Layout from "../../Layout";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useTranslation } from "react-i18next";
import { ReportDataTable } from "./ReportDataTable";
// import { ImportDataTable } from "./DataTable";

export const ReportPage = () => {
  const { t, i18n } = useTranslation(["common", "input", "report"], {
    nsMode: "fallback",
  });

  return (
    <>
      <Layout>
        <Box display={"flex"} flexDirection={"column"} flex={1} height={"100%"}>
          <Box
            p={"40px 40px"}
            display={"flex"}
            flexDirection="column"
            bgcolor={"var(--white)"}
            gap={"40px"}
            alignItems={"start"}
            flex={1}
          >
            <Grid2 container width={"100%"} rowGap={"20px"}>
              <Grid2 xs={12} md={6} display={{ xs: "none", md: "block" }}>
                <Typography variant="h1" color={"var(--dark)"}>
                  Filter
                </Typography>
              </Grid2>

              <Grid2
                xs={12}
                md={6}
                gap={"12px"}
                display={"flex"}
                justifyContent={"end"}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<img src="/img/export-white.svg" alt="" />}
                  //   onClick={handleOpen}
                >
                  {t("Export-Report")}
                </Button>
              </Grid2>
            </Grid2>

            <ReportDataTable />
          </Box>
        </Box>
      </Layout>
    </>
  );
};
