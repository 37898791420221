import { Box } from "@mui/material";
import { GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
// import { AddActivityTemplatesModal } from "./AddActivityTemplatesModal";

export const QuickSearchToolbar = () => {
  return (
    <Box
      marginBottom={"40px"}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      gap={4}
      sx={{
        flexDirection: { xs: "column", sm: "row" },
      }}
    >
      <GridToolbarContainer
        sx={{
          padding: 0,
          width: { xs: "100%", sm: "auto" },
        }}
      >
        <GridToolbarQuickFilter
          sx={{
            border: "1px solid var(--gray1)",
            borderRadius: "4px",
            margin: 0,
            padding: 0,
            width: "100%",
          }}
          inputProps={{
            style: {
              padding: "0px",
            },
          }}
          InputProps={{
            disableUnderline: true,
            sx: {
              padding: "9px",
              margin: 0,
              width: "100%",
            },
          }}
        />
      </GridToolbarContainer>
      <GridToolbarContainer
        sx={{
          padding: 0,
          width: { xs: "100%", sm: "auto" },
        }}
      >
        {/* <AddActivityTemplatesModal /> */}
      </GridToolbarContainer>
    </Box>
  );
};
