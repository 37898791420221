import {
  Box,
  Button,
  Hidden,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useTranslation } from "react-i18next";
import { useContext, useEffect } from "react";
import { OrganizationContext } from "../../contexts/OrganizationContext";
import { InputReactHookForm } from "../../components/input/InputReactHookForm";
import { useForm } from "react-hook-form";
import SubOrganizationsService from "../../service/subOrganizationsService";
import alertService from "../../components/alert/alertService";
import { SubOrganizations } from "../../models/SubOrganization";
import { useAuth } from "../../contexts/UserContext";

const SubOrganizationProfile = () => {
  const { t } = useTranslation(["common", "organization", "input"], {
    nsMode: "fallback",
  });
  const context = useContext(OrganizationContext);

  if (!context) {
    throw Error();
  }

  const subOrganization = context.subOrganizations?.find((item) => {
    return item.id === context.selectedSubOrganization;
  });

  const onSubmit = async (values: any) => {
    const oldAddress: string = subOrganization?.location?.address ?? "";

    const oldDistrict: string = subOrganization?.location?.district ?? "";

    if (
      values.name === subOrganization?.name &&
      values.city === subOrganization?.location?.city &&
      values.postalCode === subOrganization?.location?.postalCode &&
      values.country === subOrganization?.location?.country &&
      values.address === oldAddress &&
      values.district === oldDistrict
    ) {
      return;
    }

    try {
      const body: any = {
        _id: subOrganization?.refId,
        name: values.name,
        location: {
          city: values.city,
          postalCode: values.postalCode,
          country: values.country,
          address: values.address ?? "",
          district: values.district ?? "",
        },
      };

      if (values.name.trim() === subOrganization?.name.trim()) {
        delete body.name;
      }

      if (body.location.address.trim() === "") {
        delete body.location.address;
      }

      if (body.location.district.trim() === "") {
        delete body.location.district;
      }

      const result: SubOrganizations[] | null =
        await SubOrganizationsService.editSubOrganizations(body);

      if (result) {
        alertService.mixin(t("Successfully"));
        context.setSubOrganizations(result);
      }
    } catch (error) {
      alertService.error(t("validation-failed"));
    }
  };

  const {
    control,
    setValue,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    reset();
    if (subOrganization) {
      setValue("name", subOrganization.name);

      setValue("address", subOrganization.location?.address ?? "");

      setValue("district", subOrganization.location?.district ?? "");

      setValue("city", subOrganization.location?.city ?? "");

      setValue("postalCode", subOrganization.location?.postalCode ?? "");

      setValue("country", subOrganization.location?.country ?? "");
    }
  }, [subOrganization]);

  const checkKeyDown = (event: any) => {
    if (event.key === "Enter") event.preventDefault();
  };

  const { isAdmin } = useAuth();

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
        onKeyDown={(e) => checkKeyDown(e)}
      >
        <Box
          sx={{
            height: "100%",
            width: "100%",
          }}
          display={"flex"}
          flexDirection={"column"}
          gap={"40px"}
        >
          <Box
            flex={1}
            sx={{
              height: "100%",
              width: "100%",
            }}
          >
            <Grid2
              container
              rowSpacing={"20px"}
              columnSpacing={"40px"}
              sx={{
                width: "100%",
              }}
            >
              <Grid2 xs={12} md={6}>
                <InputReactHookForm
                  label={t("Sub-Organization-Name-Display")}
                  info={t("Sub-Organization-Name-Display")}
                  name="name"
                  placeholder={t("Sub-Organization-Name-Display")}
                  control={control}
                  defaultValue={subOrganization?.name ?? ""}
                  required
                  rules={{
                    required: t("form-reqired-error", {
                      param: t("Sub-Organization-Name-Display"),
                    }),
                  }}
                />
              </Grid2>
              <Grid2 xs={12} md={6}>
                <Stack gap={"8px"}>
                  <Typography variant="h3">
                    {t("sub-organizations-id")}
                  </Typography>
                  <Paper
                    // component={"form"}
                    elevation={0}
                    variant="outlined"
                    sx={{
                      height: "36px",
                      boxSizing: "border-box",
                      flexGrow: 1,
                      p: 0,
                      backgroundColor: "var(--gray5)",
                    }}
                  >
                    <Stack direction={"row"} height={"100%"}>
                      <Typography
                        alignContent={"center"}
                        sx={{ flexGrow: 1, px: "12px" }}
                      >
                        {subOrganization?.id}
                      </Typography>
                      <IconButton
                        color="primary"
                        sx={{ p: "10px" }}
                        aria-label="directions"
                        onClick={() =>
                          navigator.clipboard.writeText(
                            subOrganization?.id.toString() ?? ""
                          )
                        }
                      >
                        <img src="/img/copy.svg" alt="copy" />
                      </IconButton>
                    </Stack>
                  </Paper>
                </Stack>
              </Grid2>

              <Grid2 xs={12} md={6}>
                <InputReactHookForm
                  label={t("Address")}
                  info={t("Address")}
                  name="address"
                  placeholder={t("Address")}
                  control={control}
                  defaultValue={subOrganization?.location?.address ?? ""}
                  rules={{}}
                />
              </Grid2>
              <Hidden mdDown>
                <Grid2 xs={12} md={6}></Grid2>
              </Hidden>
              <Grid2 xs={12} md={6}>
                <InputReactHookForm
                  label={t("district")}
                  info={t("district")}
                  name="district"
                  placeholder={t("district")}
                  rules={{}}
                  control={control}
                  defaultValue={subOrganization?.location?.district ?? ""}
                />
              </Grid2>
              <Grid2 xs={12} md={6}>
                <InputReactHookForm
                  label={t("State")}
                  info={t("State")}
                  name="city"
                  placeholder={t("State")}
                  rules={{
                    required: t("form-reqired-error", {
                      param: t("State"),
                    }),
                  }}
                  control={control}
                  defaultValue={subOrganization?.location?.city ?? ""}
                  required
                />
              </Grid2>
              <Grid2 xs={12} md={6}>
                <InputReactHookForm
                  label={t("Zip-Code")}
                  info={t("Zip-Code")}
                  name="postalCode"
                  placeholder={t("Zip-Code")}
                  rules={{
                    required: t("form-reqired-error", {
                      param: t("Zip-Code"),
                    }),
                  }}
                  control={control}
                  defaultValue={subOrganization?.location?.postalCode ?? ""}
                  required
                />
              </Grid2>
              <Grid2 xs={12} md={6}>
                <InputReactHookForm
                  label={t("Country")}
                  info={t("Country")}
                  name="country"
                  placeholder={t("Country")}
                  rules={{
                    required: t("form-reqired-error", {
                      param: t("Country"),
                    }),
                  }}
                  control={control}
                  defaultValue={subOrganization?.location?.country ?? ""}
                  required
                />
              </Grid2>
            </Grid2>
          </Box>

          {isAdmin() && (
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={!isValid}
            >
              {t("Save")}
            </Button>
          )}
        </Box>
      </form>
    </>
  );
};

export default SubOrganizationProfile;
