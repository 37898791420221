import { Paper, Stack, SvgIconProps, styled } from "@mui/material";
import { useContext, useEffect } from "react";
import { OrganizationContext } from "../../contexts/OrganizationContext";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view";
import Buildings from "../../components/icon/Buildings";
import { useAuth } from "../../contexts/UserContext";

const CustomTreeItem = styled(TreeItem)({
  [`& .${treeItemClasses.content}`]: {
    // height: "36px",
    padding: "6px 12px 6px 12px",
    [`&.${treeItemClasses.selected}`]: {
      backgroundColor: "var(--green)",
      color: "var(--white)",
      [`&.${treeItemClasses.focused}`]: {
        backgroundColor: "var(--green)",
      },
      [`&:hover`]: {
        backgroundColor: "var(--green)",
      },
      "& img": {
        filter: "invert(100%) saturate(0%)",
      },
      "& svg": {
        color: "white",
      },
    },
  },
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: 20,
    borderLeft: `1px solid var(--gray5)`,
  },
  [`& .${treeItemClasses.iconContainer}`]: {
    width: "24px",
    height: "24px",
  },
});

const SubOrganizations = () => {
  const { organization } = useAuth();

  const context = useContext(OrganizationContext);

  if (!context) {
    throw Error();
  }

  const onSubOrganizationClick = (id: string) => {
    context.setSelectedSubOrganization(id);
  };

  const subOrganzations = context.subOrganizations?.map((item) => {
    return (
      <CustomTreeItem
        key={item.id}
        itemId={item.id.toString()}
        onClick={() => onSubOrganizationClick(item.id)}
        label={item.name}
      />
    );
  });

  const EndIcon = (props: SvgIconProps) => {
    return <img src="/img/location.svg" alt="icon" />;
  };

  const CollapseIcon = () => {
    return <Buildings color="secondary" />;
  };

  useEffect(() => {}, [organization]);

  return (
    <Stack gap={"20px"} boxSizing={"border-box"}>
      {/* <Stack direction={"row"} gap={"12px"} height={"40px"}>
        <Typography
          variant="text1"
          color={"secondary"}
          width={"120px"}
          height={"100%"}
          alignContent={"center"}
        >
          {t("Workspace-ID")}:
        </Typography>
        <Paper
          // component={"form"}
          elevation={0}
          variant="outlined"
          sx={{
            height: "100%",
            boxSizing: "border-box",
            flexGrow: 1,
            p: 0,
            backgroundColor: "var(--gray5)",
          }}
        >
          <Stack direction={"row"} height={"100%"}>
            <Typography
              alignContent={"center"}
              sx={{ flexGrow: 1, px: "12px" }}
            >
              {organization?.id}
            </Typography>
            <IconButton
              color="primary"
              sx={{ p: "10px" }}
              aria-label="directions"
              onClick={() =>
                navigator.clipboard.writeText(organization?.id.toString() ?? "")
              }
            >
              <img src="/img/copy.svg" alt="copy" />
            </IconButton>
          </Stack>
        </Paper>
      </Stack> */}
      <Paper
        elevation={0}
        variant="outlined"
        sx={{
          p: "20px",
          maxHeight: { xs: "50vh", lg: "100%" },
          overflowY: "auto",
        }}
      >
        <SimpleTreeView
          expandedItems={["organization"]}
          defaultSelectedItems={"organization"}
          slots={{
            expandIcon: Buildings,
            collapseIcon: CollapseIcon,
            endIcon: EndIcon,
          }}
        >
          <CustomTreeItem
            itemId="organization"
            onClick={() => context.setSelectedSubOrganization(undefined)}
            label={organization?.name}
            children={subOrganzations}
          />
        </SimpleTreeView>
      </Paper>
    </Stack>
  );
};

export default SubOrganizations;
