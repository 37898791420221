import { FormControl, InputAdornment } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import CustomInput from "../../../../../components/input/CustomInput";
import { useTranslation } from "react-i18next";
import {
  AddEmissionContext,
  EmissionMode,
} from "../../../../../contexts/AddEmissionContext";
import { NUMBER_REGEX } from "../../../../../constants/form";

interface Props {
  label: string;
  info: string;
  control: Control<any, any>;
  endIcon?: JSX.Element;
}

export const AmountInput = (props: Props) => {
  const { t } = useTranslation(["common", "input"], {
    nsMode: "fallback",
  });

  const { emissionMode } = AddEmissionContext();

  return (
    <>
      <FormControl fullWidth>
        <Controller
          key="amount"
          name="amount"
          defaultValue={props.control._getWatch("amount") ?? ""}
          control={props.control}
          rules={{
            required:
              emissionMode === EmissionMode.AddEmission
                ? t("form-reqired-error", { param: t(props.label) })
                : false,
            pattern: {
              value: NUMBER_REGEX,
              message: t("only-numbers-are-allowed"),
            },
            // validate: (props) => {
            //   if (emissionMode === EmissionMode.AddActivityTemplates) {
            //     return true;
            //   }

            //   const inputValue: string = props ?? "";

            //   const splitInput: string[] = inputValue.split(".") ?? [];

            //   const number = splitInput[0] ?? "0";

            //   const decimal = splitInput ?? "0";

            //   const result = Number(number.replaceAll(",", "") + decimal);

            //   if (
            //     Number.isNaN(result) &&
            //     (splitInput.length > 2 || inputValue.split(",").length > 2)
            //   ) {
            //     return t("only-numbers-are-allowed");
            //   }
            // },
            onChange: props.control._updateValid,
          }}
          render={({ field: { onChange, value } }) => (
            <CustomInput
              label={t(props.label)}
              info={t(props.info)}
              // placeholder={t("Select-Param", {
              //   param: t(props.label),
              // })}
              placeholder={props.label}
              value={value}
              handleOnChange={onChange}
              error={props.control._formState.errors.amount && true}
              errorMessage={props.control._formState.errors.amount?.message?.toString()}
              disabled={
                emissionMode === EmissionMode.AddActivityTemplates
                  ? true
                  : false
              }
              endIcon={props.endIcon}
              required
            />
          )}
        />
      </FormControl>
    </>
  );
};
